const translations = {
  welcome: 'Welcome to meta4books',
  loading: 'laden...',
  errorpage_title: 'Er is iets fout gelopen.',
  errorpage_subtitle: 'Probeer deze pagina opnieuw te laden.',
  errorpage_contact: 'Als het nog steeds niet lukt, kan je <0>contact met ons opnemen</0>.',
  security_login: 'Inloggen',
  security_code: 'E-mailadres',
  security_email: 'E-mailadres',
  security_pw: 'Wachtwoord',
  security_pw_repeat: 'Wachtwoord herhalen',
  security_set_password: 'Stel wachtwoord in',
  security_set_password_success:
    'Wachtwoord ingesteld. U kan nu aanmelden met uw nieuw wachtwoord.',
  security_pw_dont_match: 'Wachtwoorden komen niet overeen',
  security_pw_forgotten: 'Wachtwoord vergeten?',
  security_pw_forgotten_text:
    'Vul hieronder uw e-mailadres in. We sturen dan binnen enkele minuten een e-mail waarmee een nieuw wachtwoord kan worden aangemaakt.',
  security_pw_forgotten_submit: 'Verzenden',
  security_pw_forgotten_success: 'E-mail verzonden.',

  security_invalid:
    'Het wachtwoord is niet juist of inlogcode/e-mailadres zijn niet gekend. Indien u het wachtwoord bent vergeten, dan kan u <0>een wachtwoord reset aanvragen</0>.',
  token_invalid: 'Deze link is verlopen. <0>Vraag een nieuwe aan</0>.',
  at: 'om',
  or: 'of',
  from: 'van',
  to: 'tot',
  from_date: 'Van datum',
  to_date: 'tot en met',
  close: 'Sluiten',
  date: 'Datum',
  qty: 'Aantal',
  yes: 'Ja',
  no: 'Nee',
  na: 'N.v.t',
  uom_metric: 'cm',
  uom_weight: 'gram',
  uom_time: 'min',
  months_one: '{{count}} maand',
  months_other: '{{count}} maanden',
  years_one: '{{count}} jaar',
  cb: '(CB)',
  years_other: '{{count}} jaar',
  not_blank: 'Gelieve {{field}} in te vullen',
  invalid_url: 'Gelieve een geldige url in te vullen: bijvoorbeeld http://www.boekenbank.be',
  invalid_phone_number:
    'Gelieve een geldig telefoonnummer in te vullen, bijvoorbeeld: +32476000000.',
  invalid_postal_code: 'Gelieve een geldige postcode in te vullen.',
  invalid_email: 'Gelieve een geldig emailadres in te vullen.',
  invalid_country: 'Gelieve een geldige landcode in te vullen.',
  not_alphanumeric: 'Gelieve enkel alfanumerieke waardes te gebruiken.',
  something_went_wrong: 'Oeps, met onderstaande bestanden liep er iets mis.',
  not_unique: 'Gelieve een unieke waarde in te vullen.',
  email_not_unique:
    'Dit email adres is al in gebruik, gelieve een uniek email adres in te geven. Indien u het wachtwoord van uw account bent vergeten, dan kan u <0>een wachtwoord reset aanvragen</0>.',
  reference_not_unique: 'U hebt al een order met deze referentie.',
  reference_order_reference_regex_violations:
    'Enkel de volgende speciale tekens zijn toegelaten: / \\:#€$â&;.}{)(][ ',
  not_numeric: 'Dit veld mag enkel numerieke tekens bevatten. ',
  invalid_format: 'Het formaat van dit veld is niet geldig.',
  invalid_choice: 'Gelieve een waarde te selecteren.',
  keep_format: 'Gelieve volgend formaat aan te houden: {{format}}.',
  processing_error: 'Fout bij het verwerken',
  processing_error_server: 'Fout bij het verwerken (serverfout)',
  back_to_overview: 'Terug naar het overzicht',
  back_to_previous: 'Terug naar het vorige',
  title_created_updated: 'Aangemaakt: {{created}} - Gewijzigd: {{updated}}',
  title_m4bId: 'Organisatienummer: {{m4bId}}',
  title_fixtureId: 'InstanceID: {{fixtureId}}',
  search_placeholder_minlength:
    'Zoek ISBN, titel, auteur, uitgever, reeks, ... (minstens 4 karakters)',
  search_placeholder: 'Zoek ISBN, titel, auteur, uitgever, reeks, ...',
  search_no_options: 'Geen resultaten',
  for_more_info: 'Voor meer informatie, zie',
  search_by: 'Zoeken op:',
  search_loading: 'Zoeken...',
  search_start_typing: 'Geef minstens {{minLength}} tekens in om te zoeken',
  search_placeholder_date: 'dd-mm-jjjj',
  use_value: 'Overschrijven',
  no_value: 'Geen waarde',
  show_more: 'Meer tonen',
  show_less: 'Minder tonen',
  added_by: 'Toegevoegd door',
  nav_login: 'Inloggen',
  nav_isbn_application: 'ISBN-Aanvragen',
  nav_home: 'Home',
  nav_titles: 'Titelcatalogus',
  nav_title_files: 'Titels beheren',
  nav_titles_export: 'Titels exporteren',
  nav_administration: 'Beheer',
  nav_organisation: 'Organisatie',
  nav_organisations: 'Organisaties',
  nav_organisations_publishers: 'Uitgever opzoeken',
  nav_organisations_bookstores: 'Boekhandel opzoeken',
  nav_manage_titles_upload: 'Titelbestanden opladen',
  nav_manage_media_upload: 'Mediabestanden opladen',
  nav_manage_title_add: 'Titel toevoegen',
  nav_funds: 'Fondscodes beheren',
  nav_funds_view: 'Fondscodes opzoeken',
  nav_orders_to_be_processed: 'Te verwerken orders',
  nav_orders_to_process_for_bookstore: 'Inkomende bestellingen',
  nav_order_history_processed: 'Orderhistoriek',
  nav_order_history: 'Orderhistoriek',
  nav_order_history_lines: 'Orderhistoriek',
  nav_orderlines_history_for_library: 'Bestelhistoriek',
  nav_order_history_for_library: 'Bestelhistoriek',
  nav_orders_history_for_bookstore: 'Bestelhistoriek',
  nav_order_files: 'Orderbestanden',
  nav_isbn: 'ISBN overzicht',
  nav_admin_isbn: 'ISBN-kantoor',
  nav_admin_isbn_prefix: 'Prefixen',
  nav_admin_master_prefixes: 'Masterprefixen',
  nav_admin_isbn_overview: 'ISBN-aanvragen',
  nav_admin_prefix_application_overview: 'Prefix-aanvragen',
  nav_return_request: 'Retouraanvragen',
  nav_return_history: 'Retourhistoriek',
  nav_help_faq: 'Veelgestelde vragen',
  nav_help_guide: 'Handleidingen',
  nav_help_contact: 'Contacteer ons',
  nav_quickorder: 'Snel bestellen',
  nav_shopping_cart_order: 'Bestellen',
  nav_shopping_cart: 'Winkelmandje',
  form_awardName: 'Literatuurprijs',
  form_awardYear: 'Jaar',
  form_awardResult: 'Resultaat',
  form_award_prize_required: 'De naam van de literatuurprijs is verplicht.',
  form_award_result_required: 'Het resultaat van een literatuurprijs is verplicht',
  form_award_year_nonvalid: 'De datum moet liggen tussen 1900 en 2050',
  form_next: 'Volgende',
  form_previous: 'Vorige',
  form_back: 'Terug naar vorige',
  form_title_update_success: 'Titel aangepast',
  form_title_save_success: 'Titel toegevoegd',
  form_title_media_delete_success: 'Media verwijderd',
  form_title_submit_error: 'Er zitten nog enkele fouten in het formulier!',
  form_invalid_credentials: 'Dit zijn geen geldige inloggegevens.',
  form_ftp_generate_failure: 'De gebruiker met dit id werd niet gevonden.',
  form_ftp_generate_success: 'Succesvol nieuw FTP-wachtwoord aangevraagd',
  form_organisation_success: 'Organisatie aangemaakt.',
  form_organisation_update_success: 'Organisatie aangepast.',
  form_orderline_update_success: 'Bestellijn aangepast.',
  form_order_bulk_update_success: 'Bestellijnen aangepast.',
  form_dropzone_logo_message: 'Sleep je logo hier of blader',
  form_dropzone_active_message: 'Upload hier je bestanden.',
  form_name: 'Naam',
  form_organisation_name: 'Naam',
  form_login: 'Login',
  form_pass: 'Wachtwoord',
  form_address: 'Adres',
  form_street: 'Straat',
  form_number: 'Nummer',
  form_addition: 'Bus',
  form_postal_code: 'Postcode',
  form_city: 'Gemeente of stad',
  form_country_iso: 'Land',
  form_phone_number: 'Telefoon',
  form_vat_number: 'btw-nummer',
  form_organisation_street: 'Straat',
  form_organisation_number: 'Nummer',
  form_organisation_addition: 'Bus',
  form_organisation_postal_code: 'Postcode',
  form_organisation_city: 'Gemeente of stad',
  form_organisation_country_iso: 'Land',
  form_organisation_phone_number: 'Telefoon',
  form_phone_number_helpertext:
    'Algemeen telefoonnummer, niet van een specifieke contactpersoon.  Gewenst formaat bijvoorbeeld: +32476000000',
  form_email: 'E-mail',
  form_organisation_email: 'E-mail',
  form_email_helpertext:
    'Algemeen emailadres, indien mogelijk niet van een specifiek contactpersoon.',
  form_email_helpertext_isbn: 'Algemeen emailadres, naar dit emailadres wordt de factuur gestuurd.',
  form_url: 'Website',
  form_url_helpertext: 'Volledige URL, bijvoorbeeld "https://www.boekenbank.be"',
  form_alternative_names: 'Alternatieve namen',
  form_notes: 'Notities',
  form_company_info: 'Gegevens organisatie',
  form_company_registration_number: 'Ondernemingsnummer',
  form_organisation_company_registration_number: 'Ondernemingsnummer',
  form_company_registration_number_helpertext: 'Als particulier, mag je dit veld leeg laten.',
  form_relation: 'Relatie met',
  form_is_member: 'Lid boekenbank',
  form_confirm_agree: 'OK',
  form_confirm_disagree: 'Annuleren',
  form_save: 'Opslaan',
  form_cancel: 'Annuleren',
  form_files_success: 'Alle files werden succesvol geüpload.',
  form_file_success: 'De file werd succesvol geüpload.',
  form_upload_files: 'Bestanden Uploaden',
  form_membership_from: 'Lid sinds',
  form_membership_to: 'Lid tot',
  form_membership_nr: 'Aansluitingsnummer',
  form_reset_account: 'Account opnieuw instellen',
  form_fund_code: 'Fondscode',
  form_description: 'Omschrijving',
  form_status: 'Status',
  form_status_helpertext: 'De default fondscode kan niet inactief gemaakt worden.',
  form_distributor: 'Distributeur',
  form_filter_distributor_placeholder: 'Filter op distributeur',
  form_filter_bookstore_placeholder: 'Filter op boekhandel',
  form_retourReason: 'Reden van retour',
  form_statistics_code: 'Statistiekcode',
  form_is_primary: 'Primair',
  form_is_default: 'Default fondscode',
  form_is_available: 'Vrije fondscode',
  form_is_default_helpertext:
    'Een fondscode kan enkel niet-default gemaakt worden door een andere code default te maken.',
  form_fund_success: 'Fondscode aangemaakt',
  form_fund_update_success: 'Fondscode aangepast',
  form_is_cb_member: 'Behoort tot CB-Groep',
  form_canProcessReturns: 'Kan retouraanvragen verwerken',
  form_uses_block_updates: 'Block Updates',
  form_uses_gs1: 'GS1',
  form_supply_format: 'Type titelbestanden',

  form_roles_confirmation_heading: 'Rollen wijzigen van {{organisation}}',
  form_roles_warning:
    'Volgende velden dienen nog ingegeven te worden alvorens de rol wijziging doorgevoerd kan worden:',
  form_roles_warning_empty: 'Ben je zeker dat je de rollen van {{organisation}} wil wijzigen?',
  form_distributor_code: 'Distributeurscode',
  form_distributor_code_help: 'Distributeurscode moet uniek zijn en precies 3 letters bevatten',
  form_fund_code_help: 'Fondscode moet uniek zijn en precies 3 letters bevatten',
  form_reference: 'Referentie',
  form_your_reference: 'Uw referentie',
  form_reference_help: 'Uw eigen referentie voor dit order (optioneel).',
  form_library_reference_help: 'Uw eigen referentie voor deze bestellingen (optioneel).',
  form_free_search: 'Vrij zoeken',
  form_gtin13_search: 'Zoek op ISBN of EAN',

  form_files_toobig_one: 'De grootte van het bestand overschrijdt de toegestane limiet (100 MB)',
  form_files_toobig_other: 'De grootte van het bestand overschrijdt de toegestane limiet (100 MB)',
  form_files_wrongtype_one: 'Ongeldig bestandstype.',
  form_files_wrongtype_other: 'Ongeldig bestandstype.',
  form_files_validationerror_one: 'Ongeldig bestand.',
  form_files_validationerror_other: 'Ongeldig bestand.',
  form_files_error_with_count_one: 'Er heeft zich een fout voorgedaan met {{count}} bestand.',
  form_files_error_with_count_other: 'Er heeft zich een fout voorgedaan met {{count}} bestanden.',
  form_files_uploaded_one: '{{count}} bestand geüpload',
  form_files_uploaded_other: '{{count}}/{{total}} bestanden geüpload',
  form_files_upload_success_one: '{{count}} bestand succesvol geüpload.',
  form_files_upload_success_other: '{{count}} bestanden succesvol geüpload.',

  form_placeholder_fund_code_search: 'Zoek op fondscode',
  form_placeholder_distributor_search: 'Zoek op Distributeur',
  form_placeholder_description_search: 'Zoek op omschrijving',
  form_placeholder_organisation_search:
    'Zoek op de naam van een organisatie, prefix of CB-Relatie ID...',
  form_placeholder_isbn_title_search: 'Zoek op ISBN of titel',
  form_placeholder_gtin13_search: 'Zoek op ISBN of EAN',
  form_placeholder_bookstore_search: 'Boekhandel',
  form_placeholder_reference_search: 'Filter op orderreferentie',
  form_placeholder_library_reference_search: 'Filter op bestelreferentie',
  form_placeholder_prefix_search: 'Zoek op prefix of uitgeversnaam',
  form_placeholder_bookstores_search: 'Zoek op naam of lidnummer van de boekhandel',
  form_organisation_create: 'Organisatie aanmaken',
  form_fund_create: 'Fondscode toevoegen',
  form_action_save: 'Bewaren',
  form_action_add: 'Toevoegen',
  form_action_save_and_display: 'Bewaren en bekijken',
  form_action_save_and_back: 'Bewaren en terug',
  form_action_cancel: 'Annuleren',
  form_no_filter: 'Geen filter',
  form_placeholder_distributor: 'Distributeur',

  form_isbn_prefix: 'ISBN Prefix',
  form_prefix: 'Prefix',
  form_prefix_create: 'ISBN Prefix toevoegen',
  form_prefix_create_success: 'ISBN Prefix toegevoegd',
  form_prefix_update_success: 'Prefix aangepast',
  form_prefix_remove_success: 'ISBN Prefix verwijderd',
  form_cb_relation_id: 'CB-Relatie ID',
  form_cb_relation_create: 'CB-Relatie ID toevoegen',
  form_cb_relation_create_success: 'CB-Relatie ID toegevoegd',
  form_cb_relation_update_success: 'CB-Relatie ID aangepast',
  form_cb_relation_remove_success: 'CB-Relatie ID verwijderd',
  form_department: 'Afdeling',
  form_department_name: 'Naam afdeling',
  form_department_create: 'Afdeling toevoegen',
  form_department_create_success: 'Afdeling toegevoegd',
  form_department_update_success: 'Afdeling aangepast',
  form_department_remove_success: 'Afdeling verwijderd',
  form_favorite_bookstore: 'Boekhandel',
  form_favorite_bookstore_placeholder: 'Kies een boekhandel',
  form_library: 'Bibliotheek',
  form_favorite_bookstore_add: 'Favoriet toevoegen',
  form_favorite_bookstore_add_success: 'Favoriete boekhandel toegevoegd',
  form_favorite_bookstore_remove_success: 'Favoriete boekhandel verwijderd',
  form_order_reference: 'Referentie orderlijn',
  form_flow_number: 'Stroomnr.',
  form_library_order_reference: 'Referentie bestellijn',
  form_processingType: 'Type verwerking',
  form_processingType_automatic: 'Automatisch (FTP)',
  form_processingType_manual: 'Manueel',
  form_wants_email_notification: 'Email notificaties',
  form_uses_flow_number: 'Gebruikt stroomnummer',
  form_notification_email: 'Emailadres notificaties',
  form_orderFileFormat: 'Formaat orderbestanden',
  form_orderFileFormat_csv: 'CSV',
  form_orderFileFormat_opdnaw: 'OPDNAW',
  form_login_create: 'Gebruiker toevoegen',
  form_firstName: 'Voornaam',
  form_lastName: 'Achternaam',
  form_contributor_lastName_required: 'Gelieve een achternaam in te vullen',
  form_contributor_corporateName_required: 'Gelieve een bedrijfsnaam in te vullen',
  form_drag_tooltip: 'Klik links van een auteursnaam en versleep om de volgorde te veranderen',
  form_corporateName: 'Bedrijfsnaam',
  form_isCorporateType: 'Type',
  form_isCorporate: 'Onderneming',
  form_isNotCorporate: 'Persoon',
  form_firstName_and_lastName: 'Voornaam en achternaam',
  form_emailAddress: 'Emailadres',
  form_personal_email_helpertext:
    'Jouw persoonlijk emailadres, hiermee maken we een gebruikersaccount voor je aan.',
  form_isFtpTitleMutationConsumer: 'Neemt titelmutaties af via FTP',
  form_isFtpMediaFileConsumer: 'Neemt mediabestanden en teksten af via de FTP',
  form_isWebserviceTitleConsumer: 'Neemt titelgegevens af via API',
  form_fileType: 'Bestandstype',
  form_fileType_csv: 'CSV',
  form_fileType_onix: 'ONIX',
  form_apiKey: 'API key',
  form_cashier_system: 'Type kassasysteem',
  form_order_file_type: 'Type orderbestand',
  form_bookstore_group: 'Boekhandel groep',
  form_bookstore_update_success: 'Boekhandel aangepast',
  form_isbn_update_success: 'Aanvraag aangepast',
  form_isbn_personal_info_title: 'Je persoonlijke gegevens',
  form_isbn_personal_info:
    'Met onderstaande gegevens maken we voor jou een gebruikersaccount aan zodat je volgende keer kunt inloggen. Naar dit emailadres zal ook de bevestiging van je ISBN-aanvraag en het ISBN-nummer gestuurd worden.',
  form_check_personal_info_title: 'Controleer je persoonlijke gegevens',
  form_isbn_check_personal_info:
    'Naar dit emailadres zal de bevestigingsmail en het toegekende ISBN-nummer gestuurd worden. Zijn deze gegevens niet correct, kan je contact opnemen met ISBN@meta4books.be om voor jou een eigen account aan te maken.',
  form_prefix_check_personal_info:
    'Naar dit emailadres zal de bevestigingsmail en het toegekende prefix gestuurd worden. Zijn deze gegevens niet correct, kan je contact opnemen met ISBN@meta4books.be om voor jou een eigen account aan te maken.',
  form_isbn_organisation_info_title: 'De gegevens van je organisatie',
  form_isbn_organisation_info:
    'Vul hieronder jouw gegevens in als uitgever. Wil je een apart facturatieadres voor je ISBN of prefix, neem dan na het indienen van je aanvraag contact op met ISBN@meta4books.be.',
  form_check_organisation_info_title: 'Controleer de gegevens van je organisatie',
  form_isbn_check_organisation_info:
    'Dit zijn jouw gegevens als uitgever zoals de boekhandel ze kan raadplegen. Controleer en pas het eventueel aan waar nodig. Wil je een apart facturatieadres voor het ISBN, neem dan contact op met ISBN@meta4books.be.',
  form_prefix_check_organisation_info:
    'Dit zijn jouw gegevens als uitgever zoals de boekhandel ze kan raadplegen. Controleer en pas het eventueel aan waar nodig. Wil je een apart facturatieadres voor het prefix, neem dan contact op met ISBN@meta4books.be.',
  form_info_processing_title: 'Gegevensverwerking',
  form_info_processing:
    'Boekenbank gebruikt de gegevens die u op dit formulier verstrekt louter en alleen om het ISBN-nummer toe te kennen en voor facturatie doeleinden. De gegevens die u hier verstrekt zijn consulteerbaar voor betalende leden van de Boekenbank applicatie (uitgevers en boekhandels). Boekenbank is een applicatie van Meta4books vzw, met verenigingszetel te Vestinglaan 57 bus 9, 2650 Edegem (België). Meta4books opereert als ISBN-kantoor voor Vlaanderen en Brussel en verwerkt dusdanig uw gegevens. U kunt onze privacyverklaring <0>hier</0> consulteren of contact met ons opnemen via meta4books@meta4books.be.',
  form_info_processing_label:
    'Ja, ik ga akkoord dat Boekenbank mijn gegevens verwerkt zoals hierboven omschreven.',
  form_isbn_step_publisher: 'Uitgever',
  form_isbn_step_prefix: 'Prefix',
  form_isbn_step_title: 'Titel',
  form_isbn_step_overview: 'Overzicht',
  form_isbn_choose_prefix_title: 'Kies je prefix',
  form_isbn_choose_prefix:
    'Kies een prefix op basis waarvan het ISBN-nummer gegenereerd zal worden.',
  form_isbn_no_prefixes: 'Je hebt geen beschikbare prefixen!',
  form_isbn_prefix_radio_label: '{{prefix}} - {{amount}} vrije nummers',
  form_isbn_prefix_subText:
    'Een ISBN-toekenning gebeurt altijd op basis van een uitgeversprefix waarmee het nummer wordt gegenereerd. Indien je in het verleden een ISBN-aanvraag deed, heb je mogelijk nog een prefix met vrije nummers. Je vindt dit dan terug in de e-mail met de toekenning van het ISBN.',
  form_isbn_prefix_submit_own: 'Eigen prefix opgeven',
  form_isbn_prefix_submit_own_subText:
    'Indien je je eigen prefix kent, mag je dit hieronder ingeven.',
  form_isbn_prefix_input_label: 'Prefix',
  form_isbn_prefix_input_helperText: 'Een Vlaams prefix begint met 97890 of 97894.',
  form_isbn_prefix_new: 'Nieuw prefix aanvragen',
  form_isbn_prefix_new_subText:
    'Maak hieronder een keuze indien je voor het eerst een ISBN-aanvraag doet, al je prefixen opgebruikt zijn, of je jouw ISBN-prefix niet kent. Je geeft nu een publicatie uit en in de toekomst volgen er:',
  form_isbn_prefix_new_helpText:
    'Voor leden van een Vlaamse uitgeversvereniging of Meta4books gelden kortingstarieven.',
  form_isbn_prefix_radio_1:
    'Geen - Ik reserveer één ISBN-nummer aan  € {{amount}} (excl. 21% btw).',
  form_isbn_prefix_radio_10:
    'Eens in de paar jaar - Ik reserveer 10 nummers aan € {{amount}} (excl. 21% btw).',
  form_isbn_prefix_radio_100:
    '1 tot 9 per jaar - Ik reserveer 100 nummers aan € {{amount}} (excl. 21% btw).',
  form_isbn_prefix_radio_forgot: 'Ik heb al een prefix maar ik weet het niet meer.',
  form_prefix_radio_1: 'Ik reserveer één ISBN-nummer aan  € {{amount}} (excl. 21% btw).',
  form_prefix_radio_10: 'Ik reserveer 10 nummers aan € {{amount}} (excl. 21% btw).',
  form_prefix_radio_100: 'Ik reserveer 100 nummers aan € {{amount}} (excl. 21% btw).',
  form_isbn_purchase_order_number: 'Bestelbonnummer',
  form_isbn_submit: 'Aanvraag indienen',
  form_isbn_overview_title: 'Overzicht van je ISBN-aanvraag',
  form_isbn_overview_subtitle:
    'Hieronder zie je een overzicht van je ISBN-aanvraag. Gelieve deze gegevens na te kijken voor je de aanvraag indient.',
  form_isbn_overview_organisation_title: 'Jouw organisatie',
  form_isbn_overview_prefix_title: 'Jouw aanvraag',
  form_isbn_overview_chosen_prefix_title: 'Jouw prefix',
  form_isbn_overview_chosen_prefix:
    'Het ISBN-nummer zal worden gegenereerd op basis van prefix {{prefix}}.',
  form_isbn_overview_requesting_numbers_one: 'Ik reserveer een prefix van {{count}} nummer.',
  form_isbn_overview_requesting_numbers_other: 'Ik reserveer een prefix van {{count}} nummers.',
  form_isbn_overview_title_title: 'Jouw titel',
  form_isbn_overview_billing_title: 'Facturatie',
  form_isbn_overview_billing_price:
    'Een bedrag van € {{price}} (excl. 21% btw) wordt gefactureerd. De factuur wordt op het einde van de maand gestuurd naar {{email}}.',
  form_isbn_overview_billing_body:
    'Het kan zijn dat de prijs op je factuur lager is als na controle blijkt dat je organisatie lid is van Meta4books en/of een Vlaamse uitgeversvereniging.',
  form_isbn_overview_billing_confirmation:
    'Ik ga akkoord met de hierboven vermelde vergoeding voor het ISBN-nummer dat ik aanvraag.',
  form_prefix_overview_billing_confirmation:
    'Ik ga akkoord met de hierboven vermelde vergoeding voor het prefix dat ik aanvraag.',
  form_isbn_overview_purchase_order_confirmation:
    'Ik zal nog een bestelbon mailen naar ISBN@meta4books.be omdat mijn organisatie een bestelbonnummer vereist om het ISBN-nummer te kunnen factureren. Ik begrijp dat het ISBN-nummer dan pas zal worden toegekend.',
  form_prefix_overview_purchase_order_confirmation:
    'Ik zal nog een bestelbon mailen naar ISBN@meta4books.be omdat mijn organisatie een bestelbonnummer vereist om het prefix te mogen factureren. Ik begrijp dat het prefix dan pas zal worden toegekend.',
  form_master_prefix: 'Masterprefix',
  form_master_prefix_description: 'Omschrijving',
  form_new_prefix_subText: 'Kies de grootte van het prefix dat je wilt aanvragen:',
  form_prefix_overview_subtitle:
    'Hieronder zie je een overzicht van je prefix aanvraag. Gelieve deze gegevens na te kijken voor je de aanvraag indient.',
  form_validation_invalid_gtin13: 'Dit is geen geldig 13-cijferig ISBN- of EAN-nummer',
  form_validation_title_exists: 'Deze titel is reeds gekend in onze titelcatalogus',
  form_validation_cannot_edit: 'Je hebt geen rechten om deze titel te bewerken.',
  form_contributor_role: 'Rol',

  filter_status: 'Status',
  filter_result: 'Resultaat',
  filter_type: 'Type',
  filter_mediaType: {
    media_type_front: 'Front cover',
    media_type_back: 'Back cover',
    media_type_sample_content: 'Leesfragment',
    media_type_text_flapcopy: 'Omschrijving',
    media_type_text_promotional_headline: 'Promotionele tekst',
    media_type_text_annotation: 'Annotatie',
  },
  filter_is_available: 'Vrij/Niet Vrij',
  filter_cb_member: 'CB-Groep',
  filter_roles: 'Rollen',
  filter_role_enabled: 'Rol status',
  filter_gbp: 'GBP',
  filter_membership: 'Lidmaatschap',
  filter_statistics_code: 'Statistiekcode',
  filter_billingType: 'Facturatietype',
  filter_billed: 'Gefactureerd',
  filter_will_not_be_billed: 'Niet factureren?',
  filter_option_is_member: 'Lid',
  filter_option_is_no_member: 'Geen lid',
  filter_option_active: 'Actief',
  filter_option_inactive: 'Inactief',
  filter_option_vlaams: 'Vlaams',
  filter_option_nederlands: 'Nederlands',
  filter_option_import: 'Import',
  filter_option_waals: 'Waals',
  filter_option_gemengd: 'Gemengd',
  filter_option_leeg: 'Geen',
  filter_option_available: 'Vrij',
  filter_option_not_available: 'Niet vrij',
  filter_option_supply_manual: 'Manueel',
  filter_option_supply_onix: 'ONIX',
  filter_option_supply_csv: 'CSV',
  filter_option_yes: 'Ja',
  filter_option_no: 'Nee',
  filter_option_will_be_billed: 'Wel factureren',
  filter_option_will_not_be_billed: 'Niet factureren',
  filter_option_no_role: 'Geen rol',

  sort_by: 'Sorteren op',
  sort_option_most_relevant_first: 'Meest relevant',
  sort_option_first_published_asc: 'Eerst verschenen',
  sort_option_last_published: 'Laatst verschenen',
  sort_option_collection_asc: 'Reeksnummer oplopend',
  sort_option_collection_desc: 'Reeksnummer aflopend',
  sort_option_publishingStatus_asc: 'Artikelstatus oplopend',
  sort_option_publishingStatus_desc: 'Artikelstatus aflopend',

  home_page_register_helper:
    'Is je organisatie lid van Boekenbank maar je hebt nog geen login? <0>Neem dan contact op</0>.',
  home_page_isbn_helper:
    'Als uitgever kun je ook zonder je aan te melden een ISBN-aanvraag indienen. Er wordt dan een login voor je aangemaakt:',
  home_page_isbn_button: 'ISBN aanvraag indienen',
  home_page_isbn_more_info: 'Vind hier meer <0>informatie over het ISBN</0>.',
  home_page_pitch_title:
    'Welkom op Boekenbank, hét centrale B2B platform om op een eenvoudige manier gegevens en bestellingen uit te wisselen van boeken en andere publicaties.',
  home_page_pitch_subtitle:
    'Zo brengen we boekhandels, uitgevers, distributeurs en bibliotheken samen en ondersteunen we hun commerciële werking.',
  home_page_usp_1:
    'In Boekenbank worden de ISBN-nummers toegekend voor de publicaties van Vlaamse en Brusselse uitgevers.',
  home_page_usp_2:
    'Boekenbank heeft een volledige digitale catalogus van boeken en andere publicaties die in Vlaanderen worden verdeeld.',
  home_page_usp_3:
    'Als centraal bestelplatform zorgt Boekenbank dat bestellingen van boekhandels automatisch naar de juiste leveranciers worden gestuurd.',
  home_page_usp_4:
    'Op meerdere manieren stelt Boekenbank deze titelgegevens beschikbaar voor boekverkopers, kassasystemen, bibliotheken en andere partijen. Meer informatie vind je op <0>de website van Meta4Books</0>.',
  home_page_usp_5: 'Bibliotheken kunnen ook bestellingen plaatsen bij boekhandels.',
  home_page_usp_6:
    'Boekenbank is de referentiedatabank voor de <0>gereglementeerde boekenprijs</0> in Vlaanderen.',
  home_page_more_info:
    'Wil je meer weten over de voordelen en tarieven van boekenbank? <0>Bezoek onze website.</0>',
  home_page_terms_of_service: 'Gebruikersvoorwaarden',
  home_page_privacy_declaration: 'Privacyverklaring',
  home_page_about: 'Over Boekenbank',

  contact_page_employee_description_hannelore_hendrickx:
    '<0>Hannelore Hendrickx is onze administratieve miljoenpoot. Zij zorgt dat de uitgevers snel de nodige ISBN-nummers krijgen en helpt hen bij alle praktische vragen. Ze werkt onder andere mee aan het constant verbeteren van de kwaliteit van onze data en verzorgt mee de website en nieuwsbrieven.</0><0>Je kunt bij Hannelore terecht met al je vragen over het aanvragen van een ISBN (of ISBN-prefix) en de facturatie daarvan. Maar ook voor een extra gebruiker voor je organisatie, een kleine fondswijziging of het bijwerken van je gegevens. Tenslotte helpt Hannelore je ook met je algemene vragen over wie we zijn, wat Boekenbank is en de administratieve aspecten van lidmaatschap.</0><0>Je kunt Hannelore ook contacteren als het niet duidelijk is aan wie je je best richt.</0><0>Hannelore werkt niet op woensdag.</0>',
  contact_page_employee_description_olivier_fuchs:
    '<0>Olivier Fuchs is onze bouwmeester en hoofd van Meta4books. Hij is verantwoordelijk voor de interne werking, maar is ook voor de verdere ontwikkeling van Boekenbank, niet alleen functioneel maar ook hoe we beter kunnen integreren met onze vele leden en partners.</0><0>Je kunt bij Olivier terecht met je technische (maar ook functionele) vragen over de werking van Boekenbank, bijvoorbeeld als je een fout tegenkomt, de documentatie niet duidelijk is of een geautomatiseerde koppeling wil opzetten.</0><0>Schrijf hem zeker ook aan als je suggesties hebt voor verbeteringen of een samenwerking wilt aangaan. Je kunt aan hem ook al je vragen richten over ONIX of een beter inzicht in onze data en het optimaal gebruik ervan.</0><0>Tenslotte kun je ook bij Olivier terecht voor informatie over onze tarieven, facturatie van het lidgeld, grote fondswijzigingen, de Thema classificatie en de vaste boekenprijs.</0>',

  not_a_member: 'Deze organisatie is geen lid van Boekenbank',

  multiple_files: 'Meerdere bestanden',
  processed: 'Verwerkt',
  pending: 'Aangeboden voor verwerking',
  empty: 'Leeg bestand',
  processing: 'In verwerking',
  processed_warning: 'Verwerkt met waarschuwing',
  titles_processed_one: '{{count}} titel verwerkt',
  titles_processed_other: '{{count}} titels verwerkt',
  titles_not_processed_one: '{{count}} titel niet verwerkt',
  titles_not_processed_other: '{{count}} titels niet verwerkt',
  titles_download_process_result: 'Download verwerkingsresultaat',
  result_filterByType: 'Filter op type melding:',
  result_facet_error: 'Niet verwerkt',
  result_facet_warning: 'Waarschuwing',
  result_facet_info: 'Info',

  table_headers_name: 'Naam',
  table_headers_date: 'Datum',
  table_headers_company_registration_number: 'Lidnr',
  table_headers_company_member: 'Lid',
  table_headers_roles: 'Rollen',
  table_headers_country: 'Land',
  table_headers_city: 'Plaats',
  table_headers_prefixes: 'Prefixen',
  table_headers_organisationNumber: 'Organisatienummer',
  table_headers_filename: 'Bestandsnaam',
  table_headers_through: 'Via',
  table_headers_uploaded: 'Aangeboden op',
  table_headers_by: 'Door',
  table_headers_processed: 'Voltooid op',
  table_headers_source: 'Resultaat',
  table_headers_result: 'Resultaat',
  table_headers_lineNr: 'LijnNr',
  table_headers_flow_number: 'Stroom',
  table_headers_flow_number_tooltip:
    'Stroomnummers kunnen in overleg met de distributeurs gebruikt worden om leveringen uit te splitsen.',
  table_headers_isbn: 'ISBN',
  table_headers_isbn_or_ean: 'ISBN/EAN',
  table_headers_ean: 'EAN',
  table_headers_errorcode: 'Foutcode',
  table_headers_type: 'Type',
  table_headers_order_type: '',
  table_headers_notification: 'Omschrijving',
  table_headers_code: 'Fondscode',
  table_headers_distributor: 'Distributeur',
  table_headers_description: 'Omschrijving',
  table_headers_statistics_code: 'Statistiekcode',
  table_headers_default: 'Default',
  table_headers_status: 'Status',
  table_btn_result: 'Verwerkingsrapport',
  table_headers_prefix: 'Prefix',
  table_headers_cb_relation: 'ID',
  table_headers_primary: 'Primair',
  table_headers_alert: 'Melding',
  table_headers_order_reference: 'Orderreferentie',
  table_headers_library_order_reference: 'Bestelreferentie',
  table_headers_line_reference: 'Lijnreferentie',
  table_headers_line_reference_short: 'Lijnref.',
  table_headers_reason: 'Reden',
  table_headers_note: 'Toelichting',
  table_headers_retour_reference: 'Retourreferentie',
  table_headers_kind: 'Soort',
  table_headers_ordered_at: 'Besteld op',
  table_headers_bookstore: 'Boekhandel',
  table_headers_distributors: 'Distributeur(s)',
  table_headers_library: 'Bibliotheek',
  table_headers_num_titles: 'Titels',
  table_headers_num_pieces: 'Stuks',
  table_headers_resale_value: 'Verkoopswaarde',
  table_headers_price: 'Adviesprijs',
  table_headers_price_total: 'Totaal',
  table_headers_title: 'Titel',
  table_headers_product_form: 'Productvorm',
  table_headers_username: 'Gebruikersnaam',
  table_headers_createdAt: 'Aangemaakt',
  table_headers_lastLoginAt: 'Laatst aangemeld',
  table_headers_isbn_free: 'Vrij',
  table_headers_isbn_reserved: 'Gereserveerd',
  table_headers_isbn_occupied: 'Bezet',
  table_headers_isbn_used: 'Gekend',
  table_headers_isbn_applied: 'In aanvraag',
  table_headers_organisation: 'Organisatie',
  table_headers_publisher: 'Uitgever',
  table_headers_active: 'Actief',
  table_headers_billing_type: 'Facturatietype',
  table_headers_master_prefix: 'Masterprefix',
  table_headers_creation_date: 'Aanmaakdatum',
  table_headers_master_prefix_free: 'Vrije nummers',
  table_headers_date_publisher: 'Datum/Uitgever',
  table_headers_contact_person: 'Contactpersoon',
  table_headers_publication: 'Publicatie',
  table_headers_applicant: 'Aanvrager',
  table_headers_purchase_order: 'Bestelbon',
  table_headers_department_name: 'Naam',
  table_headers_delivered: 'Geleverd',
  table_headers_cancelled: 'Geannuleerd',
  table_headers_confirmed: 'Besteld',
  table_headers_confirmed_at: 'Ingediend op',
  table_headers_department: 'Afdeling',
  table_headers_gross_price: 'Brutoprijs',
  table_headers_ordered_by: 'Besteld door',
  table_headers_additional_delivery: 'Nalevering?',
  table_headers_article_return: 'Terugsturen?',

  organisation_edit_membership_title: 'Lidmaatschap',
  organisation_edit_membership_confirm_title:
    'Ben je zeker dat je deze wijziging wil doorvoeren voor {{organisation}}?',
  organisation_edit_publisher_prefix_title: 'ISBN Prefixen',
  organisation_edit_publisher_cb_relation_title: "CB-Relatie ID's",
  organisation_edit_publisher_params_title: 'Parameters uitgever',
  organisation_edit_publisher_purchase_order_required: 'Bestelbon vereist',
  organisation_edit_publisher_is_member_gau_gewu: 'Lid GAU/GEWU',
  organisation_edit_isMemberBookstoresFlanders: 'Lid Boekhandels Vlaanderen',
  organisation_edit_publisher_can_change_gprc: 'Kan GPRC indicator zetten',
  organisation_edit_publisher_lannoogroup: 'Lannoo groep',
  organisation_edit_publisher_is_defaulter: 'Wanbetaler',
  organisation_edit_publisher_send_data_to_cb: 'Data naar CB sturen',
  organisation_edit_distributor_parameters_title: 'Parameters distributeur',
  organisation_edit_distributor_other_subtitle: 'Andere',
  organisation_edit_distributor_return_subtitle: 'Retours',
  organisation_edit_distributor_orders_subtitle: 'Orders',
  organisation_edit_data_producer_parameters_title: 'Parameters data leverancier',
  organisation_edit_data_producer_titlefiles_subtitle: 'Titelbestanden',
  organisation_edit_data_producer_externalinterfaces_subtitle: 'External interfaces',
  organisation_edit_users_title: 'Gebruikers',
  organisation_edit_data_consumer_ftp_subtitle: 'FTP',
  organisation_edit_data_consumer_api_subtitle: 'API',
  organisation_edit_data_consumer_generate_key: 'Nieuwe key',
  organisation_edit_bookstore_parameters_title: 'Parameters boekhandel',
  organisation_edit_bookstore_cashier_system_subtitle: 'Kassasysteem',
  organisation_edit_library_departments_title: 'Afdelingen',
  organisation_edit_library_favorite_bookstores_title: 'Favoriete boekhandels',
  organisation_edit_regulated_price_algorithm: 'GBP algoritme',
  organisation_switch_title: 'Verhangen naar andere organisatie',
  organisation_switch_cancel_cta: 'Verhangen annuleren',
  organisation_switch_button_tooltip: 'Verhangen naar andere organisatie',
  organisation_switch_success: 'Gebruiker werd succesvol verhangen',

  confirm_remove_prefix_title: 'Prefix {{prefix}} verwijderen',
  confirm_remove_prefix_content: 'Bent u zeker dat u deze prefix wil verwijderen?',
  confirm_remove_cb_relation_title: 'CB-Relatie ID {{id}} verwijderen',
  confirm_remove_cb_relation_content: 'Bent u zeker dat u deze CB-Relatie ID wil verwijderen?',
  confirm_remove_favorite_bookstore_title: 'Favoriete boekhandel {{ name }} verwijderen',
  confirm_remove_favorite_bookstore_content:
    'Bent u zeker dat u deze favoriete boekhandel wil verwijderen?',

  ROLE_LIBRARY: 'Bibliotheek',
  ROLE_BOOKSTORE: 'Boekhandel',
  ROLE_PUBLISHER: 'Uitgever',
  ROLE_DISTRIBUTOR: 'Distributeur',
  ROLE_DATA_CONSUMER: 'Data-Afnemer',
  ROLE_DATA_PRODUCER: 'Data-Leverancier',
  ROLE_ADMIN: 'Admin',
  ROLE_SUPER_ADMIN: 'Superadmin',

  page_title_titlefile_process_result: 'Verwerkingsrapport',
  page_subtitle_ftp: 'FTP',
  page_title_titles: 'Titelcatalogus',
  page_title_funds: 'Fondscodes beheren',
  page_title_funds_view: 'Fondscodes opzoeken',
  page_title_fund: 'Fondscode',
  page_title_organisations: 'Organisaties',
  page_title_titles_export: 'Titels exporteren',
  page_subtitle_titles_export:
    'Hier kun je titelgegevens exporteren naar een Excel bestand. \nDit bevat veel informatie van de titels, al worden elementen zoals de rol van een medewerker, covers of flapteksten niet geëxporteerd. \nAls je enkel een gemakkelijk leesbare samenvatting wilt, kun je een zoekopdracht vanuit de titelcatalogus exporteren. \n\nMet onderstaande filters kies je welke titels je wilt exporteren. \nDeze filters worden altijd gecombineerd: enkel de titels die aan elke voorwaarde voldoen, worden geëxporteerd naar een Excel bestand.',
  page_subtitle_return_request:
    'Hier kun je retouraanvragen indienen bij distributeurs. Boekenbank stuurt enkel de aanvraag door: de verwerking en eventuele goedkeuring gebeurt door de distributeur. \nHet is op dit moment enkel mogelijk een retouraanvraag in te dienen bij distributeurs met een directe koppeling voor het verwerken van retouraanvragen.',
  page_titles_export_btn: 'Titels exporteren',
  page_titles_export_note:
    'Bij een groot aantal resultaten die aan de voorwaarden voldoen, zal je export beperkt worden tot de eerste 10.000',
  page_title_media_files: 'Mediabestanden',
  page_title_title_files: 'Titelbestanden',
  page_title_edit_title: 'Beheer titel',
  page_title_new_title: 'Titel toevoegen',
  page_title_shopping_cart: 'Winkelmandje',
  page_title_shopping_cart_confirmation: 'Order bevestigen',
  page_title_library_shopping_cart_confirmation: 'Bestelling bevestigen',
  page_title_order_to_process: 'Te verwerken orders',
  Page_title_orders_to_process_for_bookstore: 'Te verwerken bestellingen',
  page_title_order_overview_processed: 'Overzicht orderhistoriek',
  page_title_order_overview_processed_bookstore: 'Overzicht bestellingen',
  page_title_order_overview: 'Overzicht orders',
  page_title_order_detail_customer: 'Order detail',
  page_title_order_detail: 'Historisch order detail',
  page_title_library_order_detail_customer: 'Bestelling detail',
  page_title_library_order_detail: 'Historische bestelling detail',
  page_title_library_order_overview: 'Overzicht bestellingen',
  page_title_isbn_prefixes: `Mijn prefixen en ISBN's`,
  page_title_request_isbn: 'ISBN-aanvraag',
  page_title_add_title: 'Titel toevoegen',
  page_title_request_prefix: 'Prefix aanvraag',
  page_title_prefix_application_overview: 'Te behandelen prefix aanvragen',
  page_title_prefix_application_edit: 'Prefix aanvraag',
  page_title_order_files: 'Orderbestanden',
  page_title_return_request: 'Retouraanvraag indienen',
  page_title_return_history: 'Overzicht retouraanvragen',
  page_title_retour_detail: 'Retouraanvraag detail',
  page_title_publishers: 'Uitgever opzoeken',
  page_title_bookstores: 'Boekhandels',

  page_tab_orderlines: 'Orderlijnen',
  page_tab_orders: 'Orders',
  page_tab_orderlines_bookstore: 'Bestellijnen',
  page_tab_orders_bookstore: 'Bestellingen',
  page_tab_retourlines: 'Retourlijnen',
  page_tab_retours: 'Retouraanvragen',

  clipboard_copied: 'Gekopieerd!',
  copy_isbn: 'ISBN kopiëren',
  copy_title: 'Titel kopiëren',

  catalog_num_results: 'Resultaten ({{resultCount}})',
  catalog_no_results: 'Geen resultaten',
  catalog_export: 'Exporteer samenvatting',
  catalog_export_exceeded: 'De export zal beperkt worden tot de eerste {{max}} resultaten.',
  catalog_nothing_filtered:
    'Typ een zoekopdracht in de balk bovenaan of gebruik de filters om in de titelcatalogus te zoeken.',
  title_gtin13: 'ISBN/EAN',
  checkbox_existing_gtin13: 'Gegevens overnemen van bestaand ISBN/EAN',
  new_gtin13: 'Nieuw ISBN/EAN',
  existing_gtin13: 'Bestaand ISBN/EAN',
  fetch_from_existing_gtin13: 'Gegevens ophalen van bestaand ISBN',
  gtin13_not_found: 'Dit is geen gekend ISBN of EAN',
  cta_apply_data_gtin13: 'Toepassen',
  cta_fetch: 'Haal gegevens op',
  title_title: 'Hoofdtitel',
  title_restriction_information_tooltip:
    'Dit product is standaard verborgen voor boekhandels, webshops en bibliotheken omwille van een informatierestrictie door de uitgever/distributeur.',
  title_restriction_sendToGs1_tooltip:
    'De gegevens van dit product worden naar de datapool van GS1 gestuurd',
  title_subtitle: 'Ondertitel',
  title_collectionTitle: 'Reeksnaam',
  title_collectionNumber: 'Reeksnummer',
  title_productForm: 'Productvorm',
  title_productFormDetails: 'Productvorm details',
  title_productFormEPubType: 'Epub versie',
  title_productFormKindleType: 'Kindle versie',
  title_productForm_group_physical: 'Fysieke boeken',
  title_productForm_group_ebook: 'E-boeken',
  title_productForm_group_other: 'Overige',
  title_imprint: 'Imprint',
  title_imprint_tooltip:
    'Een imprint is een handelsmerk zonder dat dit één-op-één moet overeenkomen met een zelfstandige uitgeverij. Zo kan een uitgeverij "Books Media" de imprints "Dierenvrienden" en "Koken & Smullen" hebben.  Als een imprintnaam is ingevuld, zal dit op de meeste websites getoond worden in plaats van de naam van de uitgeverij.',
  title_publisher: 'Uitgever',
  title_firstPublished: 'Verschijningsdatum',
  title_firstPublished_tooltip:
    'Dit is de (verwachte) verschijningsdatum van de publicatie met dit specifieke ISBN-nummer. Zodra het  verschenen is, verandert deze datum niet meer. De verschijningsdatum is daarom verschillend van de "verwachte leverdatum" gebruikt door een distributeur, bijvoorbeeld bij een publicatie in herdruk.',
  title_editionTypes: 'Editietypes',
  title_editionTypes_helperText:
    'Als deze publicatie geen speciale editie is, laat je dit veld best leeg.',
  title_editionType_number: 'Druknummer',
  title_edition_number_tooltip:
    'Het druknummer vermeld op de titelpagina of in het colofon. Standaard mag je hier 1 invullen, maar dat kan ook hoger zijn als het gaat om een boek dat wordt herdrukt onder een nieuw ISBN.',
  title_edition_description: 'Drukomschrijving',
  title_edition_description_helperText: "Bijvoorbeeld: 'heruitgave', 'herdruk', …",
  title_availability: 'Beschikbaarheid',
  title_supplyDate: 'Verwachte leverdatum',
  title_supplyDate_tooltip:
    'Dit is de (verwachte) datum dat de leverancier van deze publicatie stock zal kunnen uitleveren naar de boekhandel. Dit kan gebruikt worden bij een publicatie die in productie is, tijdelijk uitverkocht of in herdruk. In tegenstelling tot de verschijningdatum kan de verwachte leverdatum veranderen in de tijd.',
  title_orderTime: 'Levertermijn',
  title_orderTime_zero: 'Leverbaar binnen 24 uur',
  title_orderTime_greaterThanOne: 'Leverbaar binnen {{days}} werkdagen',
  title_orderTime_one: 'Leverbaar binnen 1 werkdag',
  title_orderTime_tooltip:
    "De levertermijn naar de Vlaamse boekhandel, uitgedrukt in werkdagen. Vul '0' in voor levertermijn binnen de 24 uur.",
  title_taxRate_code: 'btw type',
  title_taxRate_code_tooltip:
    'Hier geef je het btw-percentage mee dat in België geldt voor dit type product. Het is dus onafhankelijk van het soort organisatie van de uitgever of distributeur.  Voor de meeste productvormen zal Boekenbank zelf een btw-percentage voorstellen.',
  title_recommendedPrice: 'Gewone adviesprijs',
  title_recommendedPrice_tooltip:
    'Dit is de adviesprijs die geldt voor verkoop aan de eindconsument. Dit wordt meestal gehanteerd als een brutoprijs waarop de uitgever een kortingspercentage zal toepassen voor de boekhandel. In de periode dat zij van toepassing zijn, hebben actieprijzen en gereglementeerde prijzen prioriteit boven de gewone adviesprijs.',
  title_price_requirement: 'Je moet een adviesprijs invullen OF aangeven waarom het ongeprijsd is.',
  title_priceDescription: 'Reden ongeprijsd',
  title_discountCode: 'Kortingscode',
  title_discountCode_tooltip:
    'Dit is een kortingscategorie die gebruikt kan worden in de verkoop tussen boekhandel en uitgever. Dit is een eigen keuze van de uitgever of distributeur, afhankelijk van commerciële afwegingen. Meestal geldt de hoogste korting voor algemene boeken, een kleinere op schoolboeken en de laagste op wetenschappelijke publicaties. De categorie “Overige” kan gebruikt worden om een afwijking op de eigen standaardkortingen aan te duiden. Bij twijfel kies je best de kortingscode “Algemeen”.',
  title_in_bookshelf: 'in bookshelf',
  title_priceAction_price: 'Gewone actieprijs',
  title_priceAction_price_tooltip:
    'Een prijs die tijdelijk lager is omwille van een actie door de uitgever. Bijvoorbeeld een introductieprijs, thematische korting of andere promotie. Dit is altijd beperkt in de tijd. Zolang de actieprijs van toepassing is, heeft ze voorrang op de gewone adviesprijs. Acties wanneer de gereglementeerde boekenprijs geldt, moeten aangemeld worden als "Actieprijs binnen periode GBP"',
  title_priceActionTag_tooltip: 'Er is een actieprijs van toepassing.',
  title_flapCopy: 'Flaptekst of omschrijving',
  title_flapCopy_tooltip:
    'Beschrijft de inhoud van het boek of de aard van het product op een duidelijke maar ook wervende manier. Bij boeken is dit meestal gelijk aan de flaptekst.',
  title_promotionalHeadline: 'Promotionele tekst',
  title_promotionalHeadline_tooltip:
    'Korte, commerciële tekst van maximaal enkele zinnen, vooral om te overtuigen tot aankoop op een webshop.',
  title_annotation: 'Korte omschrijving (annotatie)',
  title_annotation_tooltip:
    'Beknopte en duidelijke omschrijving van meestal één of twee zinnen. Kan ook gebruikt worden als aanbiedingstekst naar boekverkopers.',
  title_price_from: 'Van',
  title_price_to: 'Tot',
  title_priceRegulated_price: 'Gereglementeerde Boekenprijs',
  title_priceRegulated_price_tooltip:
    'Voor alle nieuwe niet-educatieve en niet-academische publicaties in het Nederlands, geldt in Vlaanderen gedurende de eerste 6 maanden na verschijnen een gereglementeerde boekenprijs. De boekhandel mag in die periode aan de eindconsument niet meer dan 10% korting geven op deze prijs. Voor onderwijsinstellingen en bibliotheken geldt een maximale korting van respectievelijk 25% en 20%.',
  title_priceRegulatedAction_price: 'Actieprijs binnen periode GBP',
  title_priceRegulatedAction_price_tooltip:
    'In de periode dat de Vlaamse gereglementeerde boekenprijs van toepassing is, mag enkel meer dan 10% afgeweken worden van deze prijs indien er een actieprijs wordt aangemeld. Deze actieprijs mag maximaal 3 maanden duren en geldt voor alle boekverkopers.',
  title_legal_depot_number_helperText: 'Dit nummer moet beginnen met D/JJJJ/, bijvoorbeeld D/2018/',
  title_legal_depot_number_tooltip:
    'Alle publicaties van Belgische uitgevers of van auteurs van Belgische nationaliteit in het buitenland, moeten in twee exemplaren gedeponeerd worden bij de Koninklijke Bibliotheek (KBR). Het wettelijk depotnummer identificeert je publicatie bij de KBR. Voor meer informatie, zie de website van het wettelijk depot.',
  title_product_form_additional_description: 'Aanvullende productomschrijving',
  title_productFormAdditionalDescription_helperText:
    "Over de uitvoering zelf, bijvoorbeeld: 'gemaakt van berkenzaadjespapier', 'gloeit in het donker', ... Niet te verwarren met feature (zie sectie \"Promotie\").",
  title_illustrations_note: 'Omschrijving illustraties',
  title_illustrations_note_helperText:
    "Bijvoorbeeld: '20 kleurenfoto's en 15 tekeningen in zwart-wit'",
  title_hasInformationRestriction: 'Informatie restrictie',
  title_hasInformationRestriction_tooltip:
    'Publicaties met een informatie-restrictie worden standaard niet aangeboden aan afnemers van onze data of getoond aan de boekhandel. Vaak gaat het dan om ISBN-nummers voor intern gebruik door de uitgever of distributeur.',
  title_hasOrderRestriction: 'Bestelbaarheid restrictie',
  title_hasOrderRestriction_tooltip:
    'Publicaties met een bestelrestrictie zijn niet bestelbaar via Boekenbank. Ook wordt dit zo aangeboden naar afnemers van onze data.',
  title_width: 'Breedte',
  title_height: 'Hoogte',
  title_thickness: 'Dikte',
  title_duration_short: 'Duur',
  title_duration: 'Duur (min)',
  title_percentageAtHighRate: 'Percentage aan 21%',
  title_heading_contributors: 'Medewerkers',
  title_heading_bib: 'Bibliografisch',
  title_heading_target: 'Doelgroep', // NOTE: not used?
  title_heading_rel: 'Relaties met andere titels',
  title_heading_prices: 'Vrije prijzen',
  title_heading_regulatedPrice: 'Gereglementeerde boekenprijzen (GBP)',
  title_heading_restrictions: 'Restricties',
  title_heading_promo: 'Promotionele info',
  title_heading_bib_other: 'Overig bibliografisch',
  title_heading_annotation: 'Samenvatting',
  title_heading_promotional_headline: 'Promotionele tekst',
  title_heading_flap_copy: 'Omschrijving',
  title_heading_original_title_language:
    'Oorspronkelijk verschenen in het {{originalLanguage}} als: {{originalTitle}}',
  title_heading_original_title: 'Oorspronkelijk verschenen als: {{originalTitle}}',
  title_heading_original_language: 'Oorspronkelijk verschenen in het {{originalLanguage}}',
  title_heading_original_title_language_publication:
    'Oorspronkelijk verschenen in het {{originalLanguage}} in {{workFirstPublished}} als: {{originalTitle}}',
  title_heading_original_language_publication:
    'Oorspronkelijk verschenen in het {{originalLanguage}} in {{workFirstPublished}}',
  title_heading_original_title_publication:
    'Oorspronkelijk verschenen in {{workFirstPublished}} als: {{originalTitle}}',
  title_heading_original_publication: 'Oorspronkelijk verschenen in {{workFirstPublished}}',
  title_heading_frontCover: 'Front cover',
  title_heading_backCover: 'Back cover',
  title_heading_sample: 'Leesfragment',
  title_heading_nstc: 'Andere uitvoeringen en edities ({{count}})',
  title_heading_matches_one_language: 'In het {{language}} ({{count}})',
  title_heading_matches_other_languages: 'In andere talen ({{count}})',
  title_heading_translatedWork: 'Vertaald werk',
  title_heading_order: 'Bestellen',
  title_heading_other: 'Overig',
  title_discount_code: 'Kortingscode: {{code}}',
  title_discount_code_a: 'Algemeen',
  title_discount_code_w: 'Wetenschappelijk',
  title_discount_code_s: 'Studie',
  title_discount_code_o: 'Overig',
  title_created_at: 'Aangemaakt op: {{date}}',
  title_created_by: ', door: {{by}}',
  title_changed_at: 'Laatst gewijzigd op: {{date}}',
  title_changed_by: ', door: {{by}}',
  title_updated_at: 'Laatste update ontvangen op: {{date}}',
  title_contributors: 'Door ',
  title_avi_levels_new: 'AVI-Leesniveau (nieuw)',
  title_avi_levels_old: 'AVI-Leesniveau (oud)',
  title_gprc: 'Guaranteed Peer Reviewed Content',
  title_gprc_tooltip:
    'Dit is een kwaliteitslabel waarmee de Groep Educatieve en Wetenschappelijk uitgevers (GEWU) aangeeft dat deze publicatie een peer-reviewprocedure heeft doorlopen die beantwoordt aan de internationale wetenschappelijke standaard. Deze titels verschijnen op de website www.gprc.be.',
  title_gs1: 'Data delen met GS1',
  title_original_title: 'Oorspronkelijke titel',
  title_original_title_tooltip:
    'Indien het gaat om een vertaling. Titel in de oorspronkelijke taal.',
  title_original_language: 'Oorspronkelijke taal',
  title_original_language_tooltip:
    'Indien het gaat om een vertaling. Taal van het werk waarop de vertaling is gebaseerd.',
  title_other_language: 'Zelfde producten in een andere taal',
  title_other_language_tooltip:
    'Hier kun je één of meer producten koppelen die hetzelfde zijn als dit product, maar in een andere taal. Het kan gaan om het oorspronkelijke werk of vertalingen in een andere taal. Probeer wel te zorgen dat het om een vergelijkbaar product gaat. Het hoeft niet ook een paperback/hardback te zijn, maar koppel bijvoorbeeld niet de Nederlandse vertaling van "Lord of the Rings" met een Engels stickerboek met "Lord of the Rings" thema.',
  title_languages: 'Talen',
  title_nur_code: 'NUR',
  title_nur_code_classification: 'NUR classificatie',
  title_nur_code_tooltip:
    'De NUR-code is een een driecijferige code die het onderwerp van een boek weergeeft en is enkel van toepassing in het Nederlandstalige taalgebied. Intussen is zij gedateerd en zal vervangen worden door de Thema-classificatie.',
  title_num_pages: "Aantal pagina's",
  title_dimensions: 'Afmetingen (cm)',
  title_dimensions_value_height: '{{height}} (hoogte)',
  title_dimensions_value_width: '{{width}} (breedte)',
  title_dimensions_value_thickness: '{{thickness}} (dikte)',
  title_weight_short: 'Gewicht',
  title_weight: 'Gewicht (g)',
  title_legal_depot_number: 'Wettelijk depotnummer',
  title_keywords: 'Trefwoorden',
  title_keywords_create_label: 'Trefwoord aanmaken {{inputValue}}',
  title_keywords_tooltip:
    'Gebruik trefwoorden vooral 1) als het werk over een nieuw idee gaat dat nog niet in de Thema classificatie is opgenomen of om synoniemen aan te geven 2) als de titel/ondertitel niet duidelijk aangeeft waar het boek over gaat, of 3) om namen van hoofdpersonages aan te geven. Gebruik niet meer dan 20 trefwoorden.',
  title_isBibliographicalVerified: 'Bibliografisch gecontroleerd',
  title_isBibliographicalVerified_long: 'Deze titelgegevens zijn bibliografisch gecontroleerd.',
  title_isBibliographicalVerified_disabled_field:
    'Je kunt dit niet bewerken omdat de titel bibliografisch gecontroleerd is.',
  title_original_first_published: 'Verschijningsdatum oorspronkelijke titel',
  title_first_published: 'Eerste verschijningsdatum van het werk',
  title_thema: "Thema's",
  title_thema_tooltip:
    'Thema is een internationaal en meertalig classificatiesysteem voor de onderwerpen van mediaproducten. Voor producten die geen boek met tekst zijn, kun je themacodes WZ (volwassenen) of YZ (kinderen/jeugd) of hun subcodes gebruiken en deze eventueel verder verfijnen met een themabepaling.',
  title_thema_qualifiers: 'Themabepalingen',
  title_thema_qualifiers_tooltip:
    'Bepalingen worden gebruikt om de Thema-onderwerpen verder te verfijnen met betrekking tot tijdsperiode, plaats of doelgroep.',
  title_mark_favorite: 'Zet als hoofdthema',
  title_main_thema: 'Hoofdthema',
  title_edition_number: 'Bibliografisch druknummer',
  title_free_of_charge: 'Gratis',
  title_to_be_announced: 'Prijs niet gekend',
  title_tax_rate: 'incl. {{tax}} btw',
  title_tax_rate_compound: 'incl. btw waarvan {{low}}% aan 6% en {{high}}% aan 21%',
  title_work_first_published: 'Eerste publicatie',
  title_order_cb: 'Verdeeld door Centraal Boekhuis',
  title_order_cb_at: 'Vertegenwoordiging door',
  title_order_at: 'Verdeeld door',
  title_order_at_ROLE_LIBRARY: '',
  title_order_at_unknown: '',
  title_order_at_unknown_ROLE_ADMIN: 'Distributeur onbekend: vrije fondscode',
  title_order_at_unknown_ROLE_PUBLISHER: 'Distributeur onbekend',
  title_order_at_unknown_ROLE_DISTRIBUTOR:
    'Deze titel heeft geen distributeur. Wijzig de fondscode als u distributeur bent.',
  title_order_at_unknown_catalog: '',
  title_order_at_unknown_catalog_ROLE_DISTRIBUTOR: 'Distributeur onbekend',
  title_order_at_unknown_catalog_ROLE_ADMIN: 'Distributeur onbekend: vrije fondscode',
  title_order_at_unknown_catalog_ROLE_PUBLISHER: 'Distributeur onbekend',
  title_fund_code: 'Fondscode',
  title_expected_on: 'verwacht op {{date}}',
  title_expected_on_short: 'verwacht op {{date}}',
  title_sample_content_btn: 'Leesfragment',
  title_edit_btn: 'Bewerken',
  title_copy_btn: 'Dupliceren',
  title_copy_btn_info: 'Nieuwe titel toevoegen vertrekkende van de gegevens van dit ISBN/EAN',
  title_order_history: 'Bestelhistoriek',
  title_order_history_empty: 'U hebt deze titel nog niet besteld.',
  title_edit_section_helper: 'Ga snel naar de gewenste sectie:',
  title_edit_section_gtin13: 'ISBN',
  title_edit_section_data: 'Titel en reeks',
  title_edit_section_form: 'Uitvoering',
  title_edit_section_bib: 'Bibliografisch',
  title_edit_section_contributors: 'Auteurs en medewerkers',
  title_edit_section_commercial: 'Prijs en beschikbaarheid',
  title_edit_section_relations: 'Relaties',
  title_edit_section_texts: 'Teksten',
  title_edit_section_mediaFiles: 'Mediabestanden',
  title_edit_section_thema: 'Thema en trefwoorden',
  title_edit_section_targetAudience: 'Doelgroep (jeugd)',
  title_edit_section_promotion: 'Promotie',
  title_edit_section_education: 'Educatief',
  title_age_range: 'Leeftijd',
  title_ageRange_from: 'Leeftijd van',
  title_ageRange_from_tooltip:
    'Indien de doelgroep van je boek 16+ is, laat je dit veld best gewoon leeg.',
  title_ageRange_to: 'Leeftijd tot',
  title_aviLevelsOld: 'AVI-Leesniveau (oud)',
  title_aviLevelsNew: 'AVI-Leesniveau (nieuw)',
  title_aviLevelsOld_short: 'oud',
  title_aviLevelsNew_short: 'nieuw',
  title_age_range_from_to_equal: '{{from}}',
  title_age_range_from_to: '{{from}} tot {{to}}',
  title_age_range_from: 'vanaf {{from}}',
  title_replacement_of: 'Vervanger van',
  title_replacement_of_tooltip:
    'Hier kies je een ander product waarvan dit product de vervanger is. Bij boeken kan dit bijvoorbeeld een co-editie zijn die bedoeld is voor een andere markt. Of een oudere versie van dit boek onder een ander ISBN. Wanneer het product onder "vervanger van" besteld wordt, zal automatisch het product dat je nu aan het bewerken bent in de plaats voorgesteld worden.',
  title_replaced_by: 'Vervangen door',
  title_replaced_by_tooltip:
    'Hier kies je een ander product dit product vervangt. Bij boeken kan dit bijvoorbeeld een nieuwere versie onder een ander ISBN zijn van het product dat je nu aan het bewerken bent. Wanneer het product dat je nu aan het bewerken bent besteld wordt, zal automatisch het product onder "vervangen door" voorgesteld worden.',
  title_feature: 'Feature',
  title_feature_helperText: "Bijvoorbeeld: 'met gratis knuffel', '3-in-1', ...",
  title_feature_tooltip:
    'Een feature is een eigenschap waar je de aandacht op wil vestigen voor promotionele doeleinden.',
  title_awards: 'Literatuurprijzen',
  title_part_title: 'Deeltitel',
  title_part_title_tooltip: 'Nummer of versie binnen de (leer)methode.',
  title_nstc: 'NSTC',
  // title_work_first_published: 'Datum eerste publicatie',
  title_work_first_published_tooltip:
    'De datum dat het werk (onder een ander ISBN) voor het eerst is verschenen, bijvoorbeeld bij heruitgave, nieuwe editie of vertaling.',
  title_section_title: 'Sectietitel',
  title_section_title_tooltip:
    "Type boek, bijvoorbeeld 'Docentenhandleiding', 'Werkboek', 'Leesboek', ...",

  title_section_gtin13: 'ISBN',
  title_section_data: 'Titelgegevens',
  title_section_format: 'Uitvoering',
  title_section_bib: 'Bibliografisch',
  title_section_contributors: 'Auteurs en medewerkers',
  title_section_thema: 'Thema en trefwoorden',
  title_section_commercial: 'Prijs en beschikbaarheid',
  title_section_relations: 'Relaties',
  title_section_texts: 'Teksten',
  title_section_mediaFiles: 'Mediabestanden',
  title_section_targetAudience: 'Doelgroep (kinder- en jeugdboeken)',
  title_section_promotion: 'Promotie',
  title_section_education: 'Educatief',

  title_collection_numeric: ', nr. {{number}}',
  title_collection_alphanumeric: ', {{number}}',
  title_collection_no_number: `Deel van reeks`,
  title_collection_numeric_with_title: '{{title}}, nr. {{number}}',
  title_collection_alphanumeric_with_title: '{{title}}, {{number}}',
  title_collection_no_number_with_title: `Deel van reeks '{{title}}'`,
  title_add_contributor_btn: 'Voeg auteur of medewerker toe',
  title_add_another_contributor_btn: 'Voeg nog een auteur of medewerker toe',

  title_add_award_btn: 'Voeg een literatuurprijs toe',
  title_add_another_award_btn: 'Voeg nog een literatuurprijs toe',

  title_more_languages: 'Meerdere talen',

  media_files_upload:
    'Upload een mediabestand (.pdf, .jpg, .jpeg, .png, .gif of .webp) of .txt bestand',
  title_files_upload: 'Upload een titelbestand (.onx, .txt, .csv, .xml of .xlsx)',
  title_open_detail: 'Bekijk titel "{{title}}"',

  title_prize_tooltip: 'Genomineerd voor of winnaar van een literaire prijs.',

  fund_status_active: 'Actief',
  fund_status_not_active: 'Inactief',
  status_active: 'Actief',
  status_not_active: 'Inactief',

  not_not_applicable: 'Vlaamse prefixen mogen niet verwijderd worden.',
  prefix_in_use: 'Dit prefix wordt gebruikt in een ISBN-aanvraag',
  prefix_used_error: 'Dit prefix wordt gebruikt in een ISBN-aanvraag',
  prefix_overview: 'Prefixen overzicht',
  prefix_billing_export: 'Prefix facturatie',
  isbn_billing_export: 'ISBN facturatie',
  prefix_billing_export_dialog: 'Wil je alle nieuwe Vlaamse prefixen exporteren?',
  isbn_billing_export_dialog:
    "Wil je alle nog niet gefactureerde Vlaamse ISBN's exporteren? \nAls de export klaar is, kun je de bestanden terugvinden op de FTP server in je folder 'download/isbn_facturatie'.",
  prefix_change_organisation_button: 'Verhangen',
  prefix_too_short: 'Een prefix moet uit 6 tot 12 cijfers bestaan',
  prefix_too_long: 'Een prefix moet uit 6 tot 12 cijfers bestaan',
  prefix_out_of_range: 'Een prefix moet altijd beginnen met 978, 979 (ISBN), 54 of 87 (EAN)',
  prefix_overlap: 'Dit prefix overlapt met (of is identiek aan) een ander prefix',
  prefix_reserve_numbers: 'Nummers reserveren',
  prefix_reserve_numbers_success_one: '{{count}} nummer gereserveerd',
  prefix_reserve_numbers_success_other: '{{count}} nummers gereserveerd',
  prefix_reserve_numbers_full_error: 'Er zijn niet voldoende vrije nummers',
  prefix_available_numbers_one: 'Dit prefix heeft nog {{count}} vrij nummer',
  prefix_available_numbers_other: 'Dit prefix heeft nog {{count}} vrije nummers',
  cb_relation_id_length: 'CB-Relatie ID moet exact uit 7 cijfers bestaan',
  not_digits: 'Kan enkel uit cijfers bestaan',
  file_too_large: 'Bestand is te groot',

  'Internal Server Error': 'Interne server fout',
  Unauthorized: 'Onbevoegd',

  price_gbp: '(gereglementeerd van {{start}} tot {{end}})',
  price_gbp_past: 'Dit was de gereglementeerde boekenprijs, maar is nu niet meer van toepassing',
  price_gbp_current: 'Er is nu een Vlaamse gereglementeerde boekenprijs van toepassing',
  price_recommended: '(adviesprijs)',
  price_action: '(actieprijs van {{start}} tot {{end}})',
  price_gbp_action: '(actieprijs binnen GBP van {{start}} tot {{end}})',

  cannot_order_general: ' ',
  cannot_order_general_condensed: ' ',
  cannot_order_digital_titles: ' ',
  cannot_order_digital_titles_ROLE_BOOKSTORE: 'Digitale producten zijn niet bestelbaar.',
  cannot_order_digital_titles_ROLE_LIBRARY: 'Digitale producten zijn niet bestelbaar.',
  cannot_order_digital_titles_condensed: ' ',
  cannot_order_digital_titles_condensed_ROLE_BOOKSTORE: 'Digitale producten zijn niet bestelbaar.',
  cannot_order_digital_titles_condensed_ROLE_LIBRARY: 'Digitale producten zijn niet bestelbaar.',
  cannot_order_restriction: 'Voor de boekhandel geldt een bestelrestrictie.',
  cannot_order_restriction_ROLE_BOOKSTORE: 'Niet bestelbaar door bestelrestrictie.',
  cannot_order_restriction_ROLE_LIBRARY: 'Niet bestelbaar door bestelrestrictie.',
  cannot_order_restriction_condensed: 'Voor de boekhandel geldt een bestelrestrictie.',
  cannot_order_restriction_condensed_ROLE_BOOKSTORE: 'Niet bestelbaar door bestelrestrictie.',
  cannot_order_restriction_condensed_ROLE_LIBRARY: 'Niet bestelbaar door bestelrestrictie.',
  cannot_order_unknown_distributor: ' ',
  cannot_order_unknown_distributor_ROLE_BOOKSTORE:
    'De distributeur is onbekend. Contacteer de uitgever.',
  cannot_order_unknown_distributor_condensed: ' ',
  cannot_order_unknown_distributor_condensed_ROLE_BOOKSTORE:
    'Distributeur onbekend, contacteer uitgever.',
  cannot_order_fund_inactive: ' ',
  cannot_order_fund_inactive_ROLE_BOOKSTORE: 'Niet bestelbaar (inactief fonds).',
  cannot_order_fund_inactive_condensed: ' ',
  cannot_order_fund_inactive_condensed_ROLE_BOOKSTORE: 'Niet bestelbaar (inactief fonds).',
  cannot_order_distributor_not_member: 'Niet bestelbaar (distributeur is niet aangesloten).',
  cannot_order_distributor_not_member_condensed:
    'Niet bestelbaar (distributeur is niet aangesloten).',
  title_orderable_replacement: 'Een nieuwere versie is wel bestelbaar.',
  cart_add_success_one: '1 stuk van de volgende titel werd aan je winkelmandje toegevoegd:',
  cart_add_success_other:
    '{{count}} stuks van de volgende titel werden aan je winkelmandje toegevoegd:',
  cart_update_qty_success: 'Het totaal aantal stuks is verhoogd naar {{count}}.',
  cart_order: 'Bestellen',
  cart_order_at: 'Te bestellen bij',
  cart_order_at_cb: 'Te bestellen bij Centraal Boekhuis, vertegenwoordiging door',
  cart_order_at_cb_short: 'Te bestellen bij Centraal Boekhuis',
  cart_distributor_total: 'Totaal voor deze distributeur: ',
  cart_user_total: 'Totaal voor deze gebruiker: ',
  cart_total: 'Totale verkoopwaarde order: ',
  cart_library_total: 'Totaal (brutoprijs): ',
  cart_library_order_confirmed_title: 'Bestelling is geplaatst',
  cart_library_order_confirmed_text:
    'De bestelling is geplaatst bij de boekhandel.<br>Geplaatste bestellingen kun je raadplegen in je bestelhistoriek.',
  cart_place_order: 'Order plaatsen',
  cart_place_full_order: 'Alle orders plaatsen',
  cart_place_library_order: 'Bestelling plaatsen',
  cart_place_full_library_order: 'Alle bestellingen plaatsen',
  cart_change_order: 'Order wijzigen',
  cart_change_library_order: 'Bestelling wijzigen',
  cart_order_confirmed_title: 'Order is geplaatst',
  cart_order_confirmed_text:
    'Het order is geplaatst bij de distributeur(s). Zij zullen het verder afhandelen. <br />Geplaatste orders kun je raadplegen in je orderhistoriek.',
  cart_order_confirmed_return: 'Terug naar boekenbank',
  cart_empty: 'Er zit op dit moment niets in je winkelmandje.',
  cart_title_exists: 'Dit product zit al in je winkelmandje',

  orders_ordered_by:
    'Order <0>#{{orderNumber}}</0> op <1>{{date}}</1> besteld door <2>{{organisation}}</2>',
  orders_library_ordered_by:
    'Bestelling <0>#{{orderNumber}}</0> op <1>{{date}}</1> besteld door <2>{{organisation}}</2>',
  with_ref: 'met ref. <0>{{reference}}',
  orders_library_ordered_by_department: ', afdeling "{{department}}"',
  order_ordered_at: 'Besteld bij <0>{{organisation}}<0>',
  order_ordered_at_publisher: 'Uitgeverij: <0>{{organisation}}<0>',
  order_ordered_at_cb:
    'Besteld bij Centraal Boekhuis, vertegenwoordiging door <0>{{organisation}}<0>',
  order_qty: '{{count}} x',
  order_qty_pieces_one: '{{count}} stuk',
  order_qty_pieces_other: '{{count}} stuks',
  order_none_to_process: 'Er zijn op dit moment geen nieuwe orders die wachten op verwerking.',
  order_none_to_process_bookstore:
    'Er zijn op dit moment geen nieuwe bestellingen die wachten op verwerking.',
  order_mark_complete: 'Markeer als verwerkt',
  order_mark_all_complete: 'Markeer alles als verwerkt',
  order_number: 'Ordernummer',
  order_library_number: 'Bestelnummer',
  order_processed_success: 'Order succesvol verwerkt',
  order_processed_all_success: 'Alle orders succesvol verwerkt',
  order_export_all: 'Exporteer alle orders',
  order_multiple_export: 'Exporteer orders',
  orderlines_multiple_export: 'Exporteer orderlijnen',
  orderlines_library_multiple_export: 'Exporteer bestellijnen',
  orderlines_library_to_process: 'Enkel openstaande bestellijnen',
  order_single_export: 'Exporteer order',
  order_single_export_excel: 'Exporteer order (Excel)',
  order_library_export_all: 'Exporteer alle bestellingen',
  order_library_multiple_export: 'Exporteer bestellingen',
  order_library_single_export: 'Exporteer bestelling',
  order_library_processed_success: 'Bestelling succesvol verwerkt',
  order_reference: 'Order referentie',
  order_reference_by: 'Order referentie van',
  orderline_change_status_all: 'Wijzig status alle lijnen',
  orderline_submitted_to_delivered: 'Besteld → Geleverd',
  orderline_submitted_to_cancelled: 'Besteld → Geannuleerd',
  orderline_status_cancelled: 'Geannuleerd',
  orderline_status_forwarded: 'Doorbesteld',
  orderline_status_processed: 'Verwerkt', // NOTE: not used?
  orderline_status_to_process: 'Te verwerken',
  orderline_status_no_delivery: 'Niet leveren',
  orderline_status_in_stock: 'In stock',
  orderline_status_delivered: 'Geleverd',
  orderline_status_submitted: 'Besteld',
  orderline_type_G: 'Gewoon',
  orderline_type_K: 'Klant',
  orderline_type_N: 'Nieuw',
  orderline_type_S: 'Speciaal',
  orderline_total: 'Totaal',

  notifications_OrderConfirmedNotification_title: 'Nieuw inkomend order',
  notifications_bookstore_OrderConfirmedNotification_title: 'Nieuwe inkomende bestelling',
  notifications_OrderConfirmedNotification_qty: '{{nrTitles}} titels - {{totalQuantity}} stuks',
  notifications_orderWithErrorNotification_title_one: '{{count}} fout op een orderbestand',
  notifications_orderWithErrorNotification_title_other: '{{count}} fouten op een orderbestand',
  notifications_order_processed_on: 'Verwerkt op {{timestamp}}',

  isbn_prefix_application: 'Nieuw prefix aanvragen',
  isbn_register_new: 'ISBN registreren',
  isbn_new_application_btn: 'ISBN aanvragen',
  isbn_new_application_prompt: 'Ben je zeker dat je de ISBN-aanvraag wil verlaten?',
  isbn_prefix_item_title: 'Prefix {{prefix}} ({{amount}} nummers)',
  isbn_prefix_free_numbers: '{{count}} vrij',
  isbn_prefix_reserved_numbers: '{{count}} gereserveerd',
  isbn_prefix_reserved_numbers_export: 'Exporteer gereserveerde nummers',
  isbn_prefix_reserved_numbers_tooltip:
    'Deze nummers zijn ooit gereserveerd door Meta4books. We verwachten dat deze nummers geregistreerd worden  in de titelcatalogus. Ze worden niet gebruikt in ISBN-aanvragen.',
  isbn_prefix_occupied_numbers: '{{count}} bezet',
  isbn_prefix_occupied_numbers_export: 'Exporteer bezette nummers',
  isbn_prefix_occupied_numbers_tooltip:
    'Deze nummers zijn al gebruikt, maar Boekenbank verwacht niet dat ze nog aangemeld gaan worden (ze staan bijvoorbeeld op een oude publicatie of zijn enkel intern gebruikt). Toch kun je ze nog aanmelden. Ze krijgen dan de status "gekend".',
  isbn_prefix_remaining_numbers: '{{count}} overgebleven',
  isbn_prefix_application_numbers: '{{count}} aangevraagd',
  isbn_prefix_application_numbers_tooltip:
    'Deze nummers zijn toegekend aan een nog lopende ISBN-aanvraag.',
  isbn_prefix_used_numbers: '{{count}} in de titelcatalogus',

  isbn_mediaFiles_info:
    'Nadat je aanvraag is verwerkt, kan je door in te loggen en je publicatie te bewerken, een front cover, back cover en leesfragment toevoegen.',
  isbn_mediaFiles_new_title:
    'Na het bewaren kan je door je publicatie te bewerken, een front cover, back cover en leesfragment toevoegen.',
  isbn_application_overview: 'Te behandelen ISBN-aanvragen',
  isbn_application_empty_list: 'Er zijn op dit moment geen openstaande ISBN-aanvragen',
  isbn_application_edit: 'ISBN-aanvraag',
  isbn_application_accept: 'Goedkeuren',
  isbn_application_reject: 'Afwijzen',
  isbn_application_pause: 'Pauzeren',
  isbn_application_chosen_prefix_title: 'Gekozen in de aanvraag',
  isbn_application_on_hold_dialog_title: 'ISBN-aanvraag pauzeren',
  isbn_applicant_organisation_info: 'Organisatie van aanvrager',
  isbn_applicant_organisation_match: 'Matchen met bestaande organisatie',
  isbn_applicant_organisation_match_other: 'Matchen met andere organisatie',
  isbn_applicant_organisation_create: 'Nieuwe organisatie aanmaken',
  isbn_applicant_personal_info: 'Persoonlijk account van aanvrager',
  isbn_applicant_personal_create: 'Nieuw account aanmaken',
  isbn_new_prefix: 'nieuw prefix',
  isbn_available_prefixes_title: 'Te gebruiken prefix',
  isbn_no_available_prefixes: 'Er zijn geen beschikbare prefixen',
  isbn_prefix_no_organisation:
    'Deze aanvraag heeft eerst een bestaande organisatie nodig om prefix opties te tonen',
  isbn_requested_numbers_one: '{{count}} nummer',
  isbn_requested_numbers_other: '{{count}} nummers',
  requested_numbers: 'nieuw prefix van {{count}}',
  isbn_use_existing_prefix: 'Ik weet mijn prefix niet meer.',
  use_existing_prefix: 'weet niet',
  isbn_generate_prefix: 'Genereer prefix',
  isbn_prefix_amount_label: 'Aantal nummers',
  isbn_submit_application: 'Aanvraag indienen',
  isbn_application_dialog_title: 'Je ISBN-aanvraag werd goed door ons ontvangen!',
  isbn_application_dialog_content:
    'Je zal het ISBN-nummer via mail ontvangen zodra je aanvraag is verwerkt.',
  isbn_application_dialog_flemish_old: 'De factuur mailen we aan het begin van de volgende maand.',
  isbn_application_dialog_flemish_new:
    'Eventuele factuur van een nieuwe prefix mailen we aan het begin van de volgende maand.',
  isbn_application_dialog_button: 'Terug naar ISBN prefixen overzicht',
  isbn_application_dialog_anonymous_button: 'Terug naar de home pagina',
  isbn_application_invalid_prefix_error: 'Deze aanvraag heeft geen geldige prefix',
  isbn_application_thema_required_error: 'Thema code is verplicht',
  isbn_application_nur_required_error: 'NUR code is verplicht',
  isbn_application_is_defaulter_alert: 'Dit is een aanvraag van een wanbetaler!',
  prefix_application_dialog_title: 'Je prefix aanvraag werd goed door ons ontvangen!',
  prefix_application_dialog_content:
    'Je zal het prefix via mail ontvangen zodra je prefix-aanvraag is verwerkt.',
  prefix_application_requested_length: 'Gevraagde grootte',
  prefix_application_generated_prefix: 'Prefix',
  prefix_application_is_defaulter_alert: 'Dit is een aanvraag van een wanbetaler!',

  isbn_administration: 'Prefix',
  prefix_name: 'Prefix',
  prefix_reserved_numbers: 'Aantal gereserveerde nummers',
  prefix_organisation: 'Uitgever',
  prefix_billing_type: 'Facturatietype',
  prefix_billed: 'Gefactureerd?',
  prefix_will_not_be_billed: 'Wordt niet gefactureerd',
  prefix_active: 'Actief',
  prefix_application_empty_list: 'Er zijn op dit moment geen openstaande prefix aanvragen',

  master_prefix_title: 'Masterprefixen',
  master_prefix_add: 'Masterprefix toevoegen',

  facet_clearAll: 'Alle filters wissen',
  facet_productFormGroup: 'Productvorm',
  facet_ageRange: 'Leeftijd',
  facet_ageRange_placeholder: 'Filter op leeftijd',
  facet_publisher: 'Uitgever',
  facet_distributor: 'Distributeur',
  facet_publisher_placeholder: 'Filter op uitgever',
  facet_imprint: 'Imprint',
  facet_imprint_placeholder: 'Filter op imprint',
  facet_availability: 'Beschikbaarheid',
  facet_isOrderable: 'Bestelbaar',
  facet_no_ebooks: 'Geen e-boeken',
  facet_firstPublishedDateRange: 'Verschijningsdatum',
  facet_updatedDateRange: 'Datum laatst gewijzigd',
  facet_personal: 'Persoonlijke filters',
  facet_personal_tooltip: 'Als niet-lid van Meta4books kun je enkel je eigen titels raadplegen.',
  facet_own_titles: 'Eigen titels',
  facet_own_titles_publisher: 'Eigen publicatie',
  facet_own_titles_distributor: 'Eigen distributie',
  facet_languages: 'Taal',
  facet_thema: 'Thema',
  facet_thema_select: 'Selecteer thema',
  facet_thema_qualifier_select: 'Selecteer themabepaling',
  facet_thema_qualifier: 'Themabepaling',
  facet_languages_placeholder: 'Filter op taal',
  facet_avi_levels: 'AVI-Leesniveau',
  facet_fund: 'Fondscode',
  facet_fund_placeholder: 'Filter op fondscode',
  facet_author: 'Auteur',
  facet_author_placeholder: 'Filter op auteur',
  facet_collection: 'Reeksnaam',
  facet_collection_placeholder: 'Filter op reeks',
  facet_editionType: 'Editietype',
  facet_editionType_placeholder: 'Filter op editietype',

  return_add_line_title: 'Nieuwe retourlijn',
  return_lines_title: 'Nog niet ingediende retourlijnen',
  return_additional_delivery: 'Nalevering gewenst',
  return_will_be_returned: 'Artikel wordt terug gestuurd',
  return_product: 'Te retourneren product',
  return_deliveryNote: 'Nummer pakbon/levernota',
  return_invoiceNumber: 'Factuurnummer',
  return_reference: 'Lijnreferentie',
  return_comments: 'Toelichting',
  return_remember_invoice: 'Pakbon- en factuurnummer onthouden',
  return_remember_reference: 'Lijnreferentie en toelichting onthouden',
  return_save_draft: 'Retourlijn toevoegen',
  return_required_invoice: 'Pakbon- of factuurnummer is verplicht bij deze reden van retour',
  return_add_line_success: 'Retourlijn toegevoegd',
  return_confirm: 'Retouraanvraag indienen',
  return_at: 'Retour naar',
  return_at_cb: 'Retour naar Centraal Boekhuis, vertegenwoordiging door',
  retour_confirm_title: 'Retouraanvraag bevestigen',
  retour_reference_help: 'Uw eigen referentie voor deze retouraanvraag (optioneel).',
  retour_confirm_cancel: 'Retouraanvraag wijzigen',
  retour_confirm_submit: 'Retouraanvraag indienen',
  retour_confirmed_title: 'Retouraanvraag is ingediend',
  retour_confirmed_body:
    'Je retouraanvraag is ingediend bij de distributeur. Zij zullen het verder afhandelen. \n Ingediende retouraanvragen kun je raadplegen in je retourhistoriek.',
  retour_export: 'Exporteer retouraanvraag',
  retour_lines_export: 'Exporteer retourlijnen',

  quick_order_descritopn:
    'Plak hier een lijst van maximaal 100 producten die je wilt bestellen. Ze worden dan in één keer aan je winkelmandje toegevoegd. \n Plak ISBN/EAN, aantal stuks en eventueel ook lijnreferentie. \n Ze mogen van elkaar gescheiden worden met tab (na kopiëren uit Excel), komma, puntkomma of spatie.',
  quick_order_example: 'Bijvoorbeeld: 9789059242876;5;Lijnreferentie 1',
  quick_order_max: 'Maximaal 100 lijnen',
  quick_order_max_error: 'Gelieve maximaal 100 lijnen te plakken',
  quick_order_check_order: 'Bestelbaarheid controleren',
  quick_order_no_errors: 'Geen fouten',
  quick_order_error_count: 'lijnen met fouten',
  quick_order_error_isbn: 'ISBN moet 13 cijfers lang zijn',
  quick_order_error_amount: 'Aantal ontbreekt',
  quick_order_error_low: 'Aantal moet groter zijn dan 0',
  quick_order_error_high: 'Aantal moet kleiner zijn dan 10000',
  quick_order_error_reference_max: 'Lijnreferentie mag niet meer dan 255 tekens bevatten',
  quick_order_error_reference_special_characters: 'Lijnreferentie bevat ongeldige tekens',
  quick_order_error_lines: 'De volgende lijnen kunnen niet aan je winkelmandje toegevoegd worden:',
  quick_order_correct_lines:
    'Enkel de volgende lijnen zullen aan je winkelmandje worden toegevoegd:',
  quick_order_prompt: 'Ben je zeker dat je deze pagina wil verlaten?',
  quick_order_table_header_isbn: 'ISBN',
  quick_order_table_header_amount: 'Aantal',
  quick_order_table_header_title: 'Titel',
  quick_order_table_header_reference: 'Lijnreferentie',
  quick_order_table_header_error: 'Fout',
  quick_order_copy_error_lines: 'Onbestelbare lijnen naar klembord kopiëren',
  quick_order_no_results: 'Geen resultaat',
  quick_order_add_to_cart: 'Toevoegen aan winkelmandje',
  quick_order_table_title_invalid:
    'De volgende lijnen kunnen niet aan je winkelmandje toegevoegd worden:',
  quick_order_table_title_valid:
    'Enkel de volgende lijnen zullen aan je winkelmandje worden toegevoegd:',
  quick_order_table_duplicate_isbn: 'Herhalende lijn: aantallen zullen worden opgeteld',
  quick_order_table_duplicate_isbn_step2: 'Aantallen werden opgeteld',
  clipboard_copied_notistack: 'Gekopieerd naar klembord.',

  //faq
  faq_q1: 'Kan ik een account laten aanmaken voor iemand anders binnen mijn organisatie?',
  faq_a1:
    'Een nieuwe gebruiker kan je aanvragen via <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>. Vermeld in je mail altijd voor-, achternaam en e-mailadres van de persoon waarvoor het account is. Werk je voor een bibliotheek, dan mag je altijd meegeven voor welke afdeling het is (bijvoorbeeld fictie volwassenen).',
  faq_q2: 'Hoe kan ik de gegevens van mijn organisatie wijzigen?',
  faq_a2:
    'Ben je een uitgever, dan krijg je de kans je gegevens na te kijken (en eventueel aan te passen) bij een ISBN- of prefixaanvraag. In alle andere gevallen neem je best even contact op met <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>.',
  faq_q3: 'Een werknemer verlaat onze organisatie, kan ik het account laten verwijderen?',
  faq_a3:
    'Ja, we kunnen dit account inactiveren. Hiervoor stuur je best een mail naar <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>.',
  faq_q4: 'Ik vind een boek (of ander product) niet, wat moet ik doen?',
  faq_a4:
    'Wanneer je zoekt met een zoekterm en je krijgt geen (gewenste) resultaten, controleer dan of er geen typfouten staan, of probeer je zoekterm te verbreden. Kijk ook goed na of er geen zoekfilters actief zijn. Je kunt tenslotte ook proberen zoeken op het exacte ISBN/EAN (zie hieronder).<br /><br />Indien je op een specifiek ISBN of EAN zoekt, maar dit geeft geen resultaat, kijk dan zeker na of er geen typfout in het ISBN zit of er zoekfilters actief zijn. Is dat niet het geval, dan betekent het dat dat ISBN of EAN op dat moment niet gekend is in de database.<br /><br />Ben je een uitgever of distributeur, dan kun je zelf nieuwe titels toevoegen via “Titels beheren” > “Titel toevoegen” of door middel van titelbestanden.  Ben je echter een boekhandel of bibliotheek en je denkt dat het toch een voor Vlaanderen relevante titel is, kun je contact opnemen met <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a> om deze titel te laten toevoegen.',
  faq_q5: 'Ik heb ideëen voor verbeteringen en nieuwe functies, wat doe ik daarmee?',
  faq_a5:
    'Elke twee weken deployen we een nieuwe versie van Boekenbank met de laatste aanpassingen. Wij blijven Boekenbank dus constant verder ontwikkelen.  Alle ideeën zijn héél welkom. Aarzel daarom niet om contact met ons op te nemen. Graag nemen we ook de tijd om met je samen te zitten om te kijken hoe je Boekenbank gebruikt en wat beter kan.',
  faq_q6: 'Ik blijf graag op de hoogte, hebben jullie een nieuwsbrief?',
  faq_a6:
    'Drie à vier keer per jaar sturen we een nieuwsbrief uit. Als je je daarvoor wilt inschrijven, kan dat <a href="https://www.flexmail.eu/p-abcd321c27acc933c245cbdf39c30196" target="_blank">hier</a>',
  faq_q7: 'Ik heb een ISBN-aanvraag ingediend maar besef dat ik nog iets wil veranderen, kan dat?',
  faq_a7:
    'Als het gaat om de gegevens van de publicatie zelf, kun je die altijd nog aanpassen nadat de ISBN-aanvraag is goedgekeurd. Gaat het om gegevens van je organisatie, prefixgrootte of bestelbon, mail je best naar <a href="mailto:isbn@meta4books.be">isbn@meta4books.be</a>.',
  faq_q8:
    'Ik heb een ISBN- of prefix-aanvraag dubbel ingediend of wil om een andere reden mijn aanvraag annuleren, kan dat?',
  faq_a8:
    'In dat geval mail je best zo snel mogelijk naar <a href="mailto:isbn@meta4books.be">isbn@meta4books.be</a>.',
  faq_q9: 'Kan ik een ISBN verwijderen uit Boekenbank?',
  faq_a9:
    'Neen, een ISBN kan niet uit onze databank verwijderd worden. Wel kan je de titel een bestel- en informatierestrictie geven zodat het respectievelijk niet bestelbaar is en ook niet (meer) doorstroomt naar onze data-afnemers.',
  faq_q10: 'Wat als mijn boek toch niet gaat verschijnen?',
  faq_a10:
    'Als een boek uiteindelijk toch niet verschijnt, geef je het de status “zal niet verschijnen” en vink je ook de bestel- en informatierestrictie aan. Gebruik dat ISBN zeker niet voor een ander boek.',
  faq_q11: 'Kan ik een ISBN-nummer hergebruiken?',
  faq_a11:
    'Je kan een ISBN-nummer niet voor een andere publicatie gebruiken. Een ISBN-nummer dient namelijk als unieke identificatie voor elke versie van een boek. Deze gegevens zijn bovendien intussen ook al doorgestroomd naar afnemers van onze data. Als je de gegevens gaat aanpassen naar dat van een ander boek, kan dat voor verkeerde gegevens zorgen bij bibliotheken, kassasystemen en webshops. Uiteraard kun je wel het zelfde ISBN gebruiken voor een (vrijwel) ongewijzigde herdruk.',
  faq_q12: 'Wanneer moet ik een nieuw ISBN gebruiken bij herdruk of heruitgave?',
  faq_a12:
    'Hiervoor zijn geen vaste regels, maar wanneer er iets wijzigt waardoor het een ander product is, gebruik je best een nieuw ISBN. Gaat het slechts om enkele spelfouten die verbeterd zijn, mag je gerust hetzelfde ISBN blijven hanteren. Gebruik echter wél een nieuw ISBN bij: veranderen van de cover, toevoegen van een voorwoord, grotere aanpassingen, andere uitvoering enzovoort. Als je twijfelt, gebruik je best een nieuw ISBN. Je kunt immers in Boekenbank het druknummer bepalen, iets invullen in het veld “drukomschrijving”, verwijzen naar het oude boek in het veld “vervanger van” enzovoort.',
  faq_q13: 'Wat als er per ongeluk een verkeerd ISBN op een boek is gedrukt?',
  faq_a13:
    'In dat geval worden meestal stickers gedrukt met het juiste ISBN en over de verkeerde barcode geplakt.',
  faq_q14: 'Hoe kan ik de gegevens van mijn boek bewerken?',
  faq_a14:
    'Werk je samen met een distributeur in Vlaanderen? Dan vraag je aan hen om de nodige aanpassingen te doen.<br /><br />Indien niet, kun je zelf de gegevens van je titels aanpassen in de Titelcatalogus. Als je de productpagina van je boek opent, zie je rechts de knop ‘bewerken’. Vergeet na het aanpassen van de gegevens niet op de knop ‘bewaren’ te klikken zodat de wijzigingen worden opgeslagen.',
  faq_q15: 'Kan ik in bulk mijn titelgegevens bijwerken?',
  faq_a15:
    'Als je lid bent van Meta4books, kun je op verschillende manieren je titels in bulk bijwerken. Dit doe je via “Beheer titels” > “Titelbestanden opladen”. Daar vind je ook een link naar de online documentatie.',
  faq_q16:
    'Als ik een boek wil bewerken, zijn sommige velden uitgegrijsd en kan ik de gegevens niet aanpassen. Waarom is dat zo?',
  faq_a16:
    'Bibliotheken doen bibliografische controles van nieuwe titels. Deze informatie lezen wij in om onze titelgegevens te verbeteren. Vervolgens gaan wij die velden uitgrijzen zodat ze niet meer overschreven kunnen worden. Dit is dus normaal. Als je toch echte fouten zie in deze data, mag je contact opnemen met <a href="mailto:isbn@meta4books.be">isbn@meta4books.be</a>.',
  faq_q17: 'Er staat bij mijn boek “distributeur onbekend”, kan ik dit aanpassen?',
  faq_a17:
    'Als je geen lid bent van Boekenbank of niet samenwerkt met een externe distributeur die lid is, dan staat er “distributeur onbekend” bij je publicaties. Boekhandels zien hier de tekst “distributeur onbekend, contacteer uitgever”. Dit is dus normaal.<br /><br />Ben je wél lid van Boekenbank en verdeel je zelf je publicaties dan kan je een eigen fondscode toevoegen in het bewerkformulier. Vanaf dan zul jij als distributeur staan van die titel. Werk je met een externe distributeur dan neem je best contact op met hen zodat zij hun fondscode aan je publicaties kunnen toevoegen.',
  faq_q18: 'Waar vind ik meer informatie over de gereglementeerde boekenprijs?',
  faq_a18:
    'Hier vind je meer informatie over de GBP: <a href="https://www.meta4books.be/gereglementeerde-boekenprijs">https://www.meta4books.be/gereglementeerde-boekenprijs</a>.',
  faq_q19:
    'Hoe geef ik voor het verschijnen bij een boek met een gereglementeerde boekenprijs een lagere voorintekenprijs in?',
  faq_a19:
    'Je laat dan zoals gebruikelijk de gereglementeerde boekenprijs starten op dezelfde datum als de verschijningsdatum. In het veld “gewone actieprijs” geef je de prijs en termijn in van de voorintekenprijs. Zorg dat die stopt één dag voor de start van de gereglementeerde boekenprijs.',
  faq_q20:
    'In Titelbank van het Centraal Boekhuis zie ik bij mijn titels “Meta4books” staan als uitgever, is er iets mis?',
  faq_a20:
    'Dat is normaal en heeft te maken met de interne werking van Titelbank. Zowel in Boekenbank, kassasystemen, bibliotheken en webshops zal de juiste uitgeversnaam zichtbaar zijn.',
  faq_q21:
    'Ik wil graag een e-mail ontvangen als er een bestelling geplaatst wordt via Boekenbank. Hoe stel ik dit in?',
  faq_a21:
    'Hiervoor stuur je best een mail naar <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a> met daarin het e-mailadres waarnaar we deze notificatie mogen verzenden.',
  faq_q22:
    'Ik wil graag meer Franstalige titels bestellen. Kan ik toegang krijgen tot La Banque du Livre?',
  faq_a22:
    'Ja, als boekhandel die lid is van Boekenbank kan je gratis een login krijgen voor Banque du Livre. Stuur hiervoor een mail naar <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a> en dan brengen we dit voor jou in orde.',
  faq_q23: 'Kan ik in Boekenbank zien welke boekhandelskorting ik krijg bij de uitgever?',
  faq_a23:
    'Nee, deze kortingen worden onderling afgesproken tussen boekhandel en uitgever – Meta4books komt hier niet in tussen. De prijzen in Boekenbank zijn de verkoopprijzen naar de eindconsument.',
  faq_q24: 'Ik heb een bestelling via Boekenbank nog altijd niet ontvangen, wat moet ik doen?',
  faq_a24:
    'Kijk eerst even na of de bestelling inderdaad goed via Boekenbank is geplaatst. Dit kun je doen door de bestelling op te zoeken via “Orderhistoriek”. Als je het daar vindt, is de bestelling alvast goed door Boekenbank verwerkt. Je neemt dan best contact op met de distributeur – zij weten de exacte status van je bestelling.<br /><br />Indien het daar niet te vinden is, zit het misschien nog in je winkelmandje of is de bestelling misschien toch niet geplaatst. Als je werkt met orderbestanden, kan het ook zijn dat daar iets verkeerd is gelopen. Zo kan het zijn dat het boek niet bestelbaar was of (nog) niet gekend in Boekenbank. Deze fouten kun je zien onder “Orderhistoriek” > “Orderbestanden”.',
  faq_q25: 'Hoe meld ik een fout gevonden in de gegevens of prijs van een boek?',
  faq_a25:
    'Neem contact op met de uitgever of distributeur van het boek om dit te melden. Zo kunnen ze de gegevens corrigeren in Boekenbank.',
  faq_q26: 'Kan ik een automatische koppeling opzetten met mijn ERP- of kassasysteem?',
  faq_a26:
    'Ja, dat kan. Neem hiervoor contact op met <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>.',
  faq_q27:
    'Kan ik een lijst van actieve boekhandels ontvangen zodat ik bestellingen goed kan verwerken in mijn eigen systeem?',
  faq_a27:
    'Ja, dat kan. Neem hiervoor contact op met <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>. Nieuwe leden worden aangekondigd via een mailing.',
  faq_q28: 'Kan ik een lijst van uitgevers ontvangen voor mijn data-uploads naar Boekenbank?',
  faq_a28:
    'Ja, dat kan. Neem hiervoor contact op met <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a>.',
  faq_q29: 'Ik kan geen boeken bestellen via Boekenbank. Hoe los ik dit op?',
  faq_a29:
    'Als je geen bestellingen kan plaatsen, is er nog geen boekhandel ingesteld. Je mag hiervoor een mail sturen naar <a href="mailto:boekenbank@meta4books.be">boekenbank@meta4books.be</a> en dan voegen we deze toe aan jullie account.',
  faq_q30: 'Op welke manieren kan ik een bestelling ingeven in Boekenbank?',
  faq_a30:
    'Dit kan op drie manieren: via orderbestanden, door gebruik van het winkelmandje in de webapplicatie of door de functie ‘snel bestellen’.',
  faq_q31: 'Kan ik zelf ook bestellingen van bibliotheken ontvangen via Boekenbank?',
  faq_a31:
    'Ja, je kunt via Boekenbank bestellingen van bibliotheken ontvangen. Wij moeten dan eenmalig bij die bibliotheek jouw boekhandel instellen als een partij waarbij ze kunnen bestellen. Er is geen extra kost voor het ontvangen van bibliotheekbestellingen.',
  faq_q32: 'Worden mijn bestellingen even snel geleverd als ik ze bestel via Boekenbank?',
  faq_a32:
    'Ja, of je ze bestelt via Boekenbank of rechtstreeks bij de distributeur, maakt geen verschil voor de levertijd. Distributeurs met automatische koppeling krijgen al snel je bestelling doorgestuurd. De andere distributeurs krijgen een notificatie en kijken Boekenbank regelmatig na.',
  faq_q33: 'Heeft het bestellen via Boekenbank invloed op de korting die ik krijg?',
  faq_a33:
    'Het korte antwoord is: nee. Er wordt geen extra kost aangerekend door ons of de distributeur omdat een bestelling via Boekenbank geplaatst wordt. Het lange antwoord vereist iets meer uitleg, zie de vraag “Voor boeken geleverd door CB: is er een verschil tussen bestellen via Boekenbank en rechtstreeks in CB online?”.',
  faq_q34:
    'Voor boeken geleverd door CB: is er een verschil tussen bestellen via Boekenbank en rechtstreeks in CB online?',
  faq_a34:
    'Alle bestellingen via Boekenbank geplaatst bij het Centraal Boekhuis, worden uitgeleverd met een DIO-korting (Distributie in Opdracht). De korting is iets kleiner dan een DUD (Distributie uit Depot), maar je betaalt geen uitleveringskosten. Er wordt bij DIO ook enkel uitgeleverd als de boekhandel kortingsafspraken heeft gemaakt met de uitgever. Boeken met kortingscode ‘Overig’ worden trouwens sowieso onder DIO-voorwaarden uitgeleverd. Het kan als boekhandel interessant zijn om te weten of je bij CB staat aangeduid als gereguleerde of niet-gereguleerde klant. In het laatste geval is het duurder voor een uitgever om naar jou uit te leveren via CB (waar zij dus bij een DIO-opdracht de uitleverkosten dragen). Dit laatste heeft in feite niet met Boekenbank te maken, maar is toch goed om te weten aangezien alle bestellingen via Boekenbank als DIO verlopen.',
  faq_q35: 'Heeft het aantal bestellingen dat ik plaats invloed op mijn lidgeld?',
  faq_a35:
    'Nee, je betaalt een jaarlijks bedrag op basis van je omzetcategorie. Er is geen kost per bestellijn.',
  faq_q36: 'Kan ik via Boekenbank stroomnummers meegeven om mijn bestellingen uit te splitsen?',
  faq_a36:
    'Stroomnummers worden door sommige distributeurs (waaronder CB) gebruikt om bestellingen te splitsen, bijvoorbeeld alle non-fictie in één doos, aparte stroomnummers voor verschillende filialen enzovoort. Boekenbank ondersteunt dit - kassa- of ERP-systeem kan een stroomnummer meegeven in het orderbestand. Het stroomnummer is nadien zichtbaar in het ‘Order detail’ scherm van je orderhistoriek. Ook kun je via je winkelmandje een stroomnummer invullen. Dit moeten we wel eerst even voor je aan zetten.',
  faq_q37: 'Kan ik via Boekenbank een retour indienen? Hoe werkt dit?',
  faq_a37:
    'Ja, dat kan voor de distributeurs die een automatische koppeling met Boekenbank hebben én die retours op die manier willen verwerken. We noemen dit in Boekenbank een retouraanvraag omdat de uiteindelijke goedkeuring van een retourverzoek nog altijd bij de uitgever of distributeur ligt. De retouraanvragen stromen automatisch door naar het systeem van de distributeur. Het retourformulier in Boekenbank is zo gemaakt dat het altijd de informatie zal vragen die de distributeur nodig heeft voor dat type retour, maar niet meer. Het kan wel gebeuren dat men nadien nog om fotobewijs vraagt.',
  faq_cat_general: 'Algemeen',
  faq_cat_isbn: 'ISBN',
  faq_cat_gbp: 'Gereglementeerde boekenprijs',
  faq_cat_orders: 'Bestellingen',
  faq_cat_titles: 'Titelgegevens bijwerken',

  env_label_dev: 'Development',
  env_label_local: 'Local development',
  env_label_prod: 'Productie',
  env_label_staging: 'Staging',

  pdf_page_next: 'Volgende pagina',
  pdf_page_prev: 'Vorige pagina',
};

export default translations;
