import { FetchConfig } from '@react-redux-fetch/core';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';
import { FetchConfig as ReduxFetchConfig } from 'react-redux-fetch';
import {
  BulkOrderPost,
  ElasticOrder,
  NormalizedOrders,
  NormalizedOrdersPage,
  OrderLineStatusBulkPatch,
  ProcessAllOrdersDTO,
} from '../../config/api/models/shop';
import apiRoutes from '../../config/api/routes';
import { Data } from '../../config/store/types';
import { normalizePagedOrderRequest } from './dtoMapper';

export const getOrdersRequest = (url: string, baseUrl: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    requestKey: 'orders',
    url: apiRoutes.create(url),
    transform: normalizePagedOrderRequest,
    repository: {
      orders: (prev, next) => ({ ...prev, ...next }),
      ordersPage: (prev, next) => {
        return {
          ...prev,
          [baseUrl]: next,
        };
      },
    },
  };
};

export const getOrderRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: url,
    repository: {
      order: (prev, next) => next,
    },
  };
};

export const getOrdersLinesRequest =
  (requestKey: string) =>
  (url: string): FetchConfig<Data> => {
    return {
      method: 'GET',
      url: apiRoutes.create(url),
      requestKey,
      repository: {
        allOrderLines: (prev, next) => ({ [requestKey]: next }),
      },
    };
  };

export const getOrdersToProcessRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: url,
    transform: normalizePagedOrderRequest,
    repository: {
      orders: (prev, next) => ({ ...prev, ...next }),
      ordersToProcess: (prev, next) => next,
    },
  };
};

export const ordersRequest = memoizeOne(
  (url: string): ReduxFetchConfig<[string]> => ({
    resource: 'orders',
    request: (nextUrl?: string) => ({
      url: apiRoutes.create(nextUrl || url),
    }),
  })
);

export const updateOrderRequest = memoizeOne(
  (url: string): ReduxFetchConfig<[ElasticOrder]> => ({
    resource: 'order',
    method: 'PUT',
    request: (order) => ({
      url: apiRoutes.create(url),
      body: order,
      meta: {
        successMsg: i18next.t('order_processed_success'),
      },
    }),
  })
);

export const processAllOrdersRequest = memoizeOne(
  (url: string, body: ProcessAllOrdersDTO[]): FetchConfig => ({
    method: 'PUT',
    url: apiRoutes.create(url),
    requestKey: 'processAllOrders',
    fetchOptions: {
      body,
      successMsg: i18next.t('order_processed_all_success'),
    },
    repository: {
      ordersToProcess: (prev, next) => null,
    },
  })
);

export const processOrderRequest = (url: string, showSuccessMsg = false): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    fetchOptions: {
      successMsg: showSuccessMsg ? i18next.t('order_library_processed_success') : '',
    },
    repository: {
      ordersToProcess: (prev: NormalizedOrdersPage, next) => {
        const copy = { ...prev };
        const index = copy._embedded.items.findIndex((order) => url.includes(order));
        if (index >= 0) {
          copy._embedded.items.splice(index, 1);
        }
        return copy;
      },
      orders: (prev, next) => {
        const copy = { ...prev };
        const key = Object.keys(copy).find((k) => url.includes(k));
        if (key) {
          delete copy[key];
        }
        return copy;
      },
    },
  };
};

export const getOrderFilesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      orderFiles: (prev, next) => next,
    },
  };
};

export const getOrderFileRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      orderFile: (prev, next) => next,
    },
  };
};

export const getOrderFileResultRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    repository: {
      orderFileResult: (prev, next) => next,
    },
  };
};

export const getOrderLineStatusesRequest = (url: string): FetchConfig<Data> => {
  return {
    method: 'GET',
    url: apiRoutes.create(url),
    requestKey: 'orderLineStatuses',
    repository: {
      orderLineStatuses: (prev, next) => next,
    },
  };
};

export const patchOrderLineRequest = (
  url: string,
  body: { state: string },
  orderRef?: string,
  stateField: 'stateLibrary' | 'stateBookstore' = 'stateLibrary'
): FetchConfig<Data> => {
  return {
    method: 'PATCH',
    url: apiRoutes.create(url),
    fetchOptions: {
      body,
      successMsg: i18next.t('form_orderline_update_success'),
    },
    repository: {
      orders: (prev: NormalizedOrders, next) => {
        if (!orderRef) return prev;

        const newVal = { ...prev };
        const order = newVal[orderRef];
        if (order) {
          order.linesByDistributor.forEach((distributorLine) => {
            if (distributorLine.lines) {
              distributorLine.lines.forEach((line) => {
                if (line._links.changeOrderLineStatus.href === url) {
                  line[stateField] = body.state;
                }
              });
            }
          });
        }
        return newVal;
      },
    },
  };
};

export const patchOrderLinesStateRequest = (
  url: string,
  body: OrderLineStatusBulkPatch
): FetchConfig<Data> => {
  return {
    method: 'PATCH',
    url: apiRoutes.create(url),
    requestKey: 'patchOrderLinesStateRequest',
    fetchOptions: {
      body,
      successMsg: i18next.t('form_order_bulk_update_success'),
    },
    repository: {},
  };
};

export const postCheckOrderabilityRequest = (
  url: string,
  body: BulkOrderPost
): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'postCheckOrderabilityRequest',
    fetchOptions: {
      body,
    },
    repository: {
      bulkOrdersToProcess: (prev, next) => next,
    },
  };
};

export const postBulkQuickOrderRequest = (url: string, body: BulkOrderPost): FetchConfig<Data> => {
  return {
    method: 'POST',
    url: apiRoutes.create(url),
    requestKey: 'postBulkQuickOrderRequest',
    fetchOptions: {
      body,
    },
    repository: {},
  };
};
