import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from '../../../../../../components/Form';
import FormFieldWrapper from '../../../../../../components/Form/FormFieldWrapper';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import Fieldset from '../Fieldset';
import FieldsetTitle from '../FieldsetTitle';
import SectionWrapper from '../SectionWrapper';
import TitleDataField from './TitleDataField';

type Props = {
  newTitleForm?: boolean;
  isBibliographicalVerified?: boolean;
};

const TitleData = ({ newTitleForm, isBibliographicalVerified = false }: Props) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper id="data">
      <FieldsetTitle>{t('title_section_data')}</FieldsetTitle>
      <Fieldset>
        <FormFieldWrapper>
          <TitleDataField
            component={Field}
            label={
              <>
                {`${t('title_title')} (*)`}
                {isBibliographicalVerified && (
                  <InfoTooltip title={t('title_isBibliographicalVerified_disabled_field')} inline />
                )}
              </>
            }
            type="text"
            name="title"
            disabled={isBibliographicalVerified}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <TitleDataField
            component={Field}
            label={
              <>
                {t('title_subtitle')}
                {isBibliographicalVerified && (
                  <InfoTooltip title={t('title_isBibliographicalVerified_disabled_field')} inline />
                )}
              </>
            }
            type="text"
            name="subtitle"
            disabled={isBibliographicalVerified}
          />
        </FormFieldWrapper>
        <Grid container spacing={1}>
          <Grid xs={6} item>
            <TitleDataField
              component={Field}
              label={
                <>
                  {t('title_collectionTitle')}
                  {isBibliographicalVerified && (
                    <InfoTooltip
                      title={t('title_isBibliographicalVerified_disabled_field')}
                      inline
                    />
                  )}
                </>
              }
              type="text"
              name="collection.title"
              disabled={isBibliographicalVerified}
            />
          </Grid>
          <Grid xs={3} item>
            <TitleDataField
              component={Field}
              label={
                <>
                  {t('title_collectionNumber')}
                  {isBibliographicalVerified && (
                    <InfoTooltip
                      title={t('title_isBibliographicalVerified_disabled_field')}
                      inline
                    />
                  )}
                </>
              }
              type="text"
              name="collection.number"
              disabled={isBibliographicalVerified}
            />
          </Grid>
        </Grid>
      </Fieldset>
    </SectionWrapper>
  );
};

export default TitleData;
