import React, { useCallback, useEffect } from 'react';
import { getCollectionsRequest } from '../../api';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import { useFetch } from '@react-redux-fetch/hooks';
import { useSelector } from 'react-redux';
import { getCollections } from '../../selectors';
import { CollectionItem } from '../../../../../../config/api/models/dataSets';
import urlencode from '../../../../../../helpers/urlencode';

type Props = Partial<RemoteAutocompleteProps> & {
  url: string;
};

const CollectionField = ({ url, publisherName, label, ...other }: Props) => {
  const [collectionsFetch, fetchCollections] = useFetch(getCollectionsRequest);
  const collections = useSelector(getCollections);

  const createUrl = useCallback(
    (term: string) => updateQueryParameters(url, { q: urlencode(term) }),
    [url]
  );

  useEffect(() => {
    if (other.inputValue) {
      fetchCollections(createUrl(other.inputValue));
    }
  }, [createUrl, fetchCollections, other.inputValue]);

  return (
    <RemoteAutocomplete
      label=""
      {...other}
      name="collection"
      createUrlFromInput={createUrl}
      promiseState={collectionsFetch}
      makeRequest={fetchCollections}
      options={collections || []}
      getOptionValue={(collection: CollectionItem) => collection}
      getOptionLabel={(collection: CollectionItem) => collection.title}
    />
  );
};

export default CollectionField;
