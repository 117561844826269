import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../../components/Button';
import DelayedLoader from '../../../../../components/DelayedLoader';
import { TitleOrder } from '../../../../../config/api/models/shop';
import { UI_DATE_FORMAT } from '../../../../../helpers/date';
import { getUserRole } from '../../../../security/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

type Props = { onClose: () => void; titleOrderHistory: TitleOrder[] };

const OrderHistoryDialog: FC<Props> = ({ onClose, titleOrderHistory }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const roles = useSelector(getUserRole);
  const isLibrary = roles.includes('ROLE_LIBRARY');

  const totalQty = titleOrderHistory.reduce((total1 = 0, order) => {
    return order.linesByDistributor[0].lines?.reduce(
      (total2, line) => total2 + line.quantity,
      total1
    );
  }, 0);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t('title_order_history')}</DialogTitle>
      <DialogContent>
        <DelayedLoader data={titleOrderHistory}>
          {titleOrderHistory.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('date')}</TableCell>
                  <TableCell align="center">{t('qty')}</TableCell>
                  <TableCell>
                    {t(isLibrary ? 'table_headers_bookstore' : 'table_headers_distributor')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {titleOrderHistory.map((order, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.nowrap}>
                        {moment(order.orderedAt).format(UI_DATE_FORMAT)}
                      </TableCell>
                      <TableCell align="center">
                        {t('order_qty_pieces', {
                          count: order.linesByDistributor[0].lines?.reduce(
                            (total, line) => total + line.quantity,
                            0
                          ),
                        })}
                      </TableCell>
                      <TableCell>
                        {isLibrary
                          ? order._embedded?.bookstore._embedded.organisation.name
                          : order.linesByDistributor[0]._embedded.organisation.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <strong>{t('order_qty_pieces', { count: totalQty })}</strong>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableFooter>
            </Table>
          ) : (
            <Typography>{t('title_order_history_empty')}</Typography>
          )}
        </DelayedLoader>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderHistoryDialog;
