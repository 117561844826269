import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppNotification,
  OrderWithErrorNotification as OrderWithErrorAppNotification,
} from '../../../config/api/models/notifications';
import ROUTE_KEY from '../../../config/routes/routeKeys';
import usePath from '../../../config/routes/usePath';
import { apiDate } from '../../../helpers/date';

type Props = {
  notification: OrderWithErrorAppNotification;
  onClick: (notification: AppNotification, redirectPath?: string) => void;
  onDelete: () => void;
};

const OrderWithErrorNotification = ({ notification, onClick, onDelete }: Props) => {
  const { t } = useTranslation();
  const ordersPath = usePath(ROUTE_KEY.ORDER_FILES);

  const handleClick = () => {
    const path = notification.parameters.orderFileId
      ? `${ordersPath}/${notification.parameters.orderFileId}`
      : ordersPath;
    onClick(notification, path);
  };

  return (
    <MenuItem>
      <Box display={'flex'} style={{ gap: 20, width: '100%' }} alignItems={'center'}>
        <Grid container direction="column" onClick={handleClick}>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item style={{ position: 'relative' }}>
              {!notification.readAt && (
                <Typography
                  style={{
                    position: 'absolute',
                    left: -3,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  {'\u25CF'}
                </Typography>
              )}
              <Typography style={{ fontWeight: notification.readAt == null ? 500 : 400 }}>
                {t('notifications_orderWithErrorNotification_title', {
                  count: +notification.parameters.errorCount,
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="caption">
                {t('notifications_order_processed_on', {
                  timestamp: apiDate(notification.parameters.dateTime).toDateTime(),
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={onDelete} size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
    </MenuItem>
  );
};

export default OrderWithErrorNotification;
