import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../../helpers/useFetch';
import { Publisher, Publishers } from '../../../../../../config/api/types';
import { getPublishersRequest } from '../../api';
import { updateQueryParameters } from '../../../../../../helpers/hateoas';
import RemoteAutocomplete, {
  RemoteAutocompleteProps,
} from '../../../../../../components/Form/RemoteAutocomplete';
import { useCallback, useEffect, useState } from 'react';
import BibliographicField from '../../components/sections/BibliographicField';
import urlencode from '../../../../../../helpers/urlencode';
import { useField } from 'react-final-form';
import { QueryObjectValue } from '../../../../../../helpers/elasticQueryDSL';
import InfoTooltip from '../../../../../../components/InfoTooltip';

type Props = Partial<RemoteAutocompleteProps> & {
  url: string;
  publisherName?: string;
  label?: string;
  required?: boolean;
  activeFilterValue?: QueryObjectValue;
  isBibliographicalVerified?: boolean;
};

const PublisherField = ({
  url,
  publisherName,
  label,
  required,
  activeFilterValue,
  reset,
  isBibliographicalVerified,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const [publishers, fetchPublishers] = useFetch<Publishers>(getPublishersRequest());
  const [currentActiveFilterValue, setCurrentActiveFilterValue] = useState(activeFilterValue);

  const {
    input: { value: inputValue },
  } = useField('publisher');

  useEffect(() => {
    //When the activeFilterValue (aka organisationId) changes outside this facet, like when checking/unchecking the 'eigen titels' checkbox, empty this inputfield.
    if (activeFilterValue !== currentActiveFilterValue) {
      setCurrentActiveFilterValue(activeFilterValue);
      if (
        !!inputValue.organisation?.organisationId &&
        activeFilterValue !== inputValue.organisation.organisationId
      ) {
        reset();
      }
    }
  }, [inputValue, activeFilterValue, currentActiveFilterValue, reset]);

  const createUrl = useCallback(
    (term: string) => {
      return updateQueryParameters(url, { q: urlencode(term) });
    },
    [url]
  );

  useEffect(() => {
    if (publisherName && !publishers) {
      fetchPublishers(createUrl(publisherName));
    }
  }, [createUrl, fetchPublishers, publisherName, publishers]);

  return (
    <BibliographicField
      {...other}
      component={RemoteAutocomplete}
      label={
        <>
          {`${label || t('title_publisher')} ${required ? '(*)' : ''}`}
          {isBibliographicalVerified && (
            <InfoTooltip title={t('title_isBibliographicalVerified_disabled_field')} inline />
          )}
        </>
      }
      name="publisher"
      createUrlFromInput={createUrl}
      promiseState={publishers}
      makeRequest={fetchPublishers}
      options={(publishers && publishers.value && publishers.value._embedded.items) || []}
      getOptionValue={(publisher: Publisher) => publisher.publisherId}
      getOptionLabel={(publisher: Publisher) => publisher.organisation.name}
      wideDropdown
      isDisabled={isBibliographicalVerified}
    />
  );
};

export default PublisherField;
