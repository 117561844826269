import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { useFetch } from '@react-redux-fetch/hooks';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AutoSave from '../../../../components/Form/AutoSave';
import { DistributorLine, ShoppingCartLine } from '../../../../config/api/models/shop';
import formatEuro from '../../../../helpers/formatEuro';
import { getLink } from '../../../../helpers/hateoas';
import { IsbnLink } from '../../../title';
import { patchOrderLineRequest } from '../../api';
import OrderStatusSelect from '../OrderStatusSelect';

type Props = {
  lines: DistributorLine[];
  orderRef: string;
};

type FormValues = {
  state: string;
};

const OrderLibraryDetailTable = ({ lines, orderRef }: Props) => {
  const { t } = useTranslation();
  const allLines = lines.reduce(
    (total, orderLine) => (orderLine.lines ? [...total, ...orderLine.lines] : total),
    [] as ShoppingCartLine[]
  );
  const [, patchOrderLine] = useFetch(patchOrderLineRequest);

  const onSubmit = (values: FormValues, orderLine: ShoppingCartLine) => {
    const orderLineUrl = getLink(orderLine, 'changeOrderLineStatus');

    if (orderLineUrl && values.state && values.state !== orderLine.stateLibrary) {
      patchOrderLine(orderLineUrl, values, orderRef);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item style={{ width: 120 }}>
          <Typography variant="subtitle2">{t('table_headers_isbn')}</Typography>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <Typography variant="subtitle2">{t('table_headers_title')}</Typography>
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">{t('table_headers_product_form')}</Typography>
        </Grid>
        <Grid item style={{ width: 240 }}>
          <Typography variant="subtitle2">{t('table_headers_line_reference')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_num_pieces')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_gross_price')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'right', width: 80 }}>
          <Typography variant="subtitle2">{t('table_headers_price_total')}</Typography>
        </Grid>
        <Grid item style={{ textAlign: 'center', width: 160 }}>
          <Typography variant="subtitle2">{t('table_headers_status')}</Typography>
        </Grid>
      </Grid>

      <Box marginTop={1} marginBottom={1}>
        <Divider />
      </Box>

      {allLines.map((line) => (
        <React.Fragment key={line._links.shoppingCartLine.href}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ width: 120 }}>
              <Typography>
                <IsbnLink orderLine={line} />
              </Typography>
            </Grid>
            <Grid item style={{ flex: 1, overflow: 'hidden' }}>
              <Tooltip placement="top-start" title={line._embedded.title.title} enterDelay={500}>
                <Typography noWrap>{line._embedded.title.title}</Typography>
              </Tooltip>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Typography>
                {line._embedded.title.productForm.shortLabel ||
                  line._embedded.title.productForm.label}
              </Typography>
            </Grid>
            <Grid item style={{ width: 240 }}>
              <Typography>{line.reference}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'center', width: 80 }}>
              <Typography>{line.quantity}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.unitPrice)}</Typography>
            </Grid>
            <Grid item style={{ textAlign: 'right', width: 80 }}>
              <Typography>{formatEuro(line.total)}</Typography>
            </Grid>
            <Grid>
              <div style={{ textAlign: 'right', width: 160, padding: '0 15px' }}>
                <Form
                  initialValues={{ state: line.stateLibrary } as FormValues}
                  onSubmit={(values: FormValues) => onSubmit(values, line)}
                >
                  {({ handleSubmit }) => (
                    <>
                      <OrderStatusSelect colored />
                      <AutoSave save={handleSubmit} />
                    </>
                  )}
                </Form>
              </div>
            </Grid>
          </Grid>
          <Box marginTop={1} marginBottom={1}>
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default OrderLibraryDetailTable;
