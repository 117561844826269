import amber from '@mui/material/colors/amber';
import green from '@mui/material/colors/green';
import createPalette from '@mui/material/styles/createPalette';

const darkBlue = '#0C7DBF';
const logoBlue = '#147ebe';
const logoLightBlue = '#a4bde1';
const logoOrange = '#f17e21';
const primaryGreen = '#00cc33';
const primaryGreenHover = '#00b231';
// const lightBlue = '#dbedf4';
// const orange = '#ff7700';
const grey = '#3e3e3e';
const hoverGrey = '#e2e4e6';
const white = '#fff';
const darkText = '#333333';
const lightGrey = '#646464';
const activeGrey = '#27363F';
const lighterGrey = '#c1c1c1';
const lightestGrey = '#eaeaea';
const almostWhite = '#f9fafb';
const greenHighlight = '#52ba52';
const lightRed = '#f6dcde';
// Text
const navText = '#d9ddde';
const navSecondary = '#eff1f3';

// background
const navBackground = '#2B353B';
// const navBackground = '#42535c';

// messages
const success = green[600];
const error = '#d32f2f';
const warning = amber[800];

const palette = createPalette({
  primary: {
    main: logoBlue,
    contrastText: white,
    highlight: logoOrange,
  },
  secondary: {
    main: white,
  },
  error: {
    main: error,
    light: lightRed,
  },
  warning: {
    main: warning,
    contrastText: white,
  },
  background: {
    default: white,
    primary: darkBlue,
    secondary: grey,
    navigation: navBackground,
    appbar: navSecondary,
    appbarHover: hoverGrey,
    appbarActive: activeGrey,
    listEven: white,
    listOdd: almostWhite,
  },
  text: {
    primary: darkText,
    secondary: darkText,
    title: darkText,
    subtitle: grey,
    navigation: navText,
    helpertext: lightGrey,
  },
  messages: { success, error, warning },
  buttons: {
    primary: logoOrange,
    primaryGreen,
    highlight: greenHighlight,
    lightDisabled: lighterGrey,

    primaryGreenHover,
    secondaryHover: lightestGrey,
  },
  status: {
    available: '#BFF2BF',
    availableText: '#2F742F', // greenHighlight,
    expected: '#FFF1D4',
    expectedText: warning, //'#9E5F00', //'#FF9900',
    notAvailable: '#fadee5',
    notAvailableText: '#C22929', //error,
  },
  prefixLegend: {
    used: logoBlue,
    reserved: '#50ade7',
    occupied: '#0d4f78',
    application: logoOrange,
    remaining: logoLightBlue,
  },
});

export default palette;
