const ROUTE_KEY = {
  LOGIN: 'login',
  ACTIVATE: 'activate',
  MANAGE_TITLES: 'titleFiles',
  MANAGE_TITLES_UPLOAD: 'manage_titles_upload',
  MANAGE_TITLES_DETAIL: 'manage_titles_detail',
  MANAGE_TITLE: 'manage_title',
  MANAGE_MEDIA_UPLOAD: 'manage_media_upload',
  MANAGE_TITLE_ADD: 'manage_title_add',
  MANAGE_TITLE_ADD_NEW: 'manage_title_add_new',
  ADMIN: 'administration',
  FUNDS: 'funds',
  FUNDS_VIEW: 'funds_view',
  FUNDS_CREATE: 'funds_create',
  FUNDS_UPDATE: 'funds_update',
  ADMIN_ORG: 'organisations',
  ADMIN_ORG_PUBLISHERS: 'organisations_publishers',
  ADMIN_ORG_BOOKSTORES: 'organisations_bookstores',
  ADMIN_ORG_CREATE: 'create_organisation',
  ADMIN_ORG_EDIT: 'edit_organisation',
  ADMIN_ORG_USERS: 'edit_organisation_users',
  ADMIN_ORG_MEMBER: 'edit_organisation_membership',
  ADMIN_ORG_DISTRIBUTOR: 'edit_organisation_distributor',
  ADMIN_ORG_PUBLISHER: 'edit_organisation_publisher',
  ADMIN_ORG_DATA_PRODUCER: 'edit_organisation_data_producer',
  ADMIN_ORG_DATA_CONSUMER: 'edit_organisation_data_consumer',
  ADMIN_ORG_BOOKSTORE: 'edit_organisation_bookstore',
  ADMIN_ORG_LIBRARY: 'edit_organisation_library',
  TITLES: 'titles',
  TITLES_EXPORT: 'titles_export',
  TITLE: 'title',
  SHOPPING_CART_ORDER: 'shoppingCartOrder',
  SHOPPING_CART: 'shoppingCart',
  ORDERS: 'ordersToBeProcessed',
  ORDERS_FOR_BOOKSTORE: 'ordersToProcessForBookstore',
  ORDERS_HISTORY_BOOKSTORE: 'ordersHistoryForBookstore',
  ORDERS_HISTORY_BOOKSTORE_ORDERS: 'ordersHistoryForBookstoreOrders',
  ORDERS_HISTORY_PROCESSED: 'orderHistoryProcessed',
  ORDERS_HISTORY_PROCESSED_ORDERS: 'orderHistoryProcessedOrders',
  ORDERS_HISTORY: 'orderHistoryLines',
  ORDERS_HISTORY_ORDERS: 'orderHistory',
  ORDERS_HISTORY_LIBRARY: 'orderlinesHistoryForLibrary',
  ORDERS_HISTORY_LIBRARY_ORDERS: 'orderHistoryForLibrary',
  ORDER_DETAIL: 'order_detail',
  ORDER_DETAIL_BOOKSTORE: 'order_detail_bookstore',
  ORDER_FILES: 'order_files',
  ORDER_FILES_DETAIL: 'order_files_detail',
  QUICK_ORDERS: 'quickorder',
  ISBN: 'isbn',
  ISBN_APPLICATION: 'isbn_application',
  PREFIX_APPLICATION: 'prefix-application',
  ADMIN_ISBN: 'admin_isbn',
  ADMIN_ISBN_APPLICATIONS: 'admin_isbn_overview',
  ADMIN_ISBN_APPLICATION: 'admin_isbn_application',
  ADMIN_PREFIX: 'admin_isbn_prefix',
  ADMIN_PREFIX_APPLICATIONS: 'admin_prefix_application_overview',
  ADMIN_PREFIX_APPLICATION: 'admin_prefix_application',
  ADMIN_PREFIX_EDIT: 'prefix_edit',
  ADMIN_MASTER_PREFIXES: 'admin_master_prefixes',
  FORGOT_PW: 'forgot_pw',
  RETURN_REQUEST: 'returnRequest',
  RETURN_HISTORY: 'returnHistory',
  RETURN_HISTORY_RETURNS: 'returnHistoryReturns',
  RETURN_DETAIL: 'returnDetail',
  HELP: 'help',
  CONTACT: 'contact',
  FAQ: 'faq',
};

export default ROUTE_KEY;
